import {useCallback, useEffect, useRef, useState} from 'react'
import Dropzone, {DropEvent, FileRejection} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import {MdOutlineFileUpload as FileUploadIcon} from 'react-icons/md'
import {MdCheck as CheckIcon} from 'react-icons/md'
import {HiOutlineDotsVertical as DotsIcon} from 'react-icons/hi'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'

import Tag from './typography/Tag'
import {image} from '../helpers/image'
import ValidationError from 'yup/lib/ValidationError'
import useSalePageSchema from '../schemas/useSalePageSchema'
import CropImageFullScreen from './crop/CropImageFullScreen'
import {CircularProgress} from '@mui/material'
import AlertDialog from './AlertDialog'
import ButtonIcon from './ButtonIcon'
import {SimpleDialog} from './Dialog'
import MenuList from './MenuList'

const ProgressStatusIcon = ({
	status
}: {
	status: 'error' | 'success' | 'loading' | 'idle'
}) => {
	return (
		<>
			{status === 'loading' ? <CircularProgress color="primary" /> : null}
			{status === 'success' ? <CheckIcon color="green" size={50} /> : null}
		</>
	)
}

interface HelperMessageProps {
	isDragActive: boolean
	isDragReject: boolean
	isInvalidImage: boolean
}

const HelperMessage = ({
	isDragActive,
	isDragReject,
	isInvalidImage
}: HelperMessageProps) => {
	const {t} = useTranslation()

	if (isDragActive && !isDragReject) {
		return (
			<FormHelperText sx={styles.textColorActive}>
				{t('helper-image-upload-drop-here')}
			</FormHelperText>
		)
	}

	if (isDragActive && isDragReject) {
		return (
			<FormHelperText error>
				{t('helper-image-upload-file-type-not-accepted')}
			</FormHelperText>
		)
	}

	if (isInvalidImage) {
		return <FormHelperText error>{t('error-image-invalid')}</FormHelperText>
	}

	return <></>
}

interface UploadCoverImageProps {
	url?: string | null
	label: string
	previewImage: string | null
	uploadStatus?: 'error' | 'success' | 'loading' | 'idle'
	setPreviewImage: (value: string | null) => void
	onUpload: (file: File) => Promise<any>
	onDelete: () => void
}

const CoverUpload = ({
	url,
	label,
	previewImage,
	uploadStatus,
	setPreviewImage,
	onUpload,
	onDelete
}: UploadCoverImageProps) => {
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [openDeleteCoverDialog, setOpenDeleteCoverDialog] =
		useState<boolean>(false)
	const [isInvalidImage, setIsInvalidImage] = useState<boolean>(false)
	const [openCropDialog, setOpenCropDialog] = useState<boolean>(false)
	const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false)
	const [imageCrop, setImageCrop] = useState<{
		src: string
		file: File
	} | null>(null)
	const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
		null
	)
	const openOptions = Boolean(anchorElOptions)
	const inputRef = useRef<HTMLInputElement>(null)
	const {t} = useTranslation()
	const salePageSchema = useSalePageSchema()

	const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElOptions(event.currentTarget)
	}

	const handleCloseOptions = () => {
		setAnchorElOptions(null)
	}

	const handleUploadImage = async (file: File) => {
		setOpenCropDialog(false)
		handleChangeImagePreview(file)
		onUpload(file)
		uploadStatus && setShowUploadProgress(true)
	}

	const handleDropImage = async (file: File) => {
		setIsInvalidImage(false)
		const errorMessage = await handleValidateImage(file)

		if (!errorMessage) {
			handleOpenCropDialog(file)
		}

		if (errorMessage) {
			setOpenAlert(true)
		}
	}

	const handleValidateImage = async (file: File) => {
		const errorMessage = await salePageSchema['cover']
			.validate(file)
			.then(() => {
				return false
			})
			.catch((err: ValidationError) => {
				setAlertMessage(t('error-image-invalid'))
				setOpenAlert(true)
				return err.errors[0]
			})

		if (errorMessage) {
			return errorMessage
		}

		return false
	}

	const handleChangeImagePreview = async (file: File) => {
		const imageURL = image.getURL(file)
		setPreviewImage(imageURL)
	}

	const handleOpenCropDialog = (file: File) => {
		setImageCrop({
			src: URL.createObjectURL(file),
			file
		})
		setOpenCropDialog(true)
	}

	const handleDeleteCover = () => {
		setOpenDeleteCoverDialog(false)
		setPreviewImage(null)
		onDelete()
	}

	const setInitialCropImage = useCallback(async () => {
		if (url) {
			setPreviewImage(url)
			const file = await image.createFileFromURL(url, 'cover')
			const imageCrop = {
				src: url,
				file
			}

			setImageCrop(imageCrop)
		}
	}, [url, setPreviewImage])

	useEffect(() => {
		setInitialCropImage()
	}, [setInitialCropImage, previewImage])

	useEffect(() => {
		if (uploadStatus === 'success') {
			setTimeout(() => {
				setShowUploadProgress(false)
			}, 2000)
		}
	}, [uploadStatus])

	return (
		<>
			<AlertDialog
				open={openAlert}
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
				severity="error"
			/>
			<SimpleDialog
				open={openDeleteCoverDialog}
				onClose={() => setOpenDeleteCoverDialog(false)}
			>
				<Typography variant="body1">
					{t('delete-sale-page-cover-dialog-message')}
				</Typography>
				<Box sx={styles.simpleDialogActionsBox}>
					<Button
						variant="outlined"
						onClick={() => setOpenDeleteCoverDialog(false)}
					>
						{t('cancel')}
					</Button>
					<Button variant="contained" onClick={handleDeleteCover} sx={{ml: 1}}>
						{t('to-delete')}
					</Button>
				</Box>
			</SimpleDialog>
			<Dropzone
				multiple={false}
				accept={{
					'image/png': [],
					'image/jpeg': [],
					'image/jpg': []
				}}
				maxSize={10000000} // 10MB
				onDropAccepted={async (files: File[], event: DropEvent) => {
					await handleDropImage(files[0])
				}}
				onDropRejected={async (
					fileRejections: FileRejection[],
					event: DropEvent
				) => {
					await handleDropImage(fileRejections[0].file)
				}}
			>
				{({getRootProps, getInputProps, isDragActive, isDragReject}) => {
					const borderColorStyle =
						(isDragActive && !isDragReject && styles.borderColorActive) ||
						(isDragActive && isDragReject && styles.borderColorReject) ||
						(Boolean(isInvalidImage) && styles.borderColorReject)

					return (
						<Box>
							<input
								{...getInputProps()}
								ref={inputRef}
								name="cover"
								aria-label={t('label')}
								type="file"
							/>

							{previewImage && (
								<Box sx={styles.previewImageBox}>
									<Box
										sx={[
											styles.previewImageContainer,
											borderColorStyle,
											{
												backgroundImage: `url(${previewImage})`
											}
										]}
									>
										{(isDragActive || showUploadProgress) && (
											<Box
												sx={{
													alignItems: 'center',
													display: 'flex',
													justifyContent: 'center',
													height: '100%',
													width: '100%',
													backgroundColor: 'rgba(0,0,0,0.7)',
													borderRadius: '4px'
												}}
											>
												{isDragActive && (
													<HelperMessage
														isDragActive={isDragActive}
														isDragReject={isDragReject}
														isInvalidImage={isInvalidImage}
													/>
												)}
												{showUploadProgress && uploadStatus ? (
													<ProgressStatusIcon status={uploadStatus} />
												) : null}
											</Box>
										)}
									</Box>
									<Box
										sx={{
											bottom: 0,
											display: 'flex',
											gap: 1,
											justifyContent: 'flex-end',
											p: 1,
											position: 'absolute',
											right: 0,
											width: '100%'
										}}
									>
										<ButtonIcon
											{...getRootProps()}
											title={t('upload-image')}
											icon={<FileUploadIcon size={20} />}
											onClick={() => inputRef.current?.click()}
										/>
										<ButtonIcon
											title={t('actions')}
											icon={<DotsIcon size={20} />}
											onClick={handleOpenOptions}
											aria-controls={openOptions ? t('options') : undefined}
											aria-haspopup="true"
											aria-expanded={openOptions ? 'true' : undefined}
										/>
										<MenuList
											open={openOptions}
											anchorEl={anchorElOptions}
											onClose={handleCloseOptions}
											list={[
												{
													label: 'to-edit',
													onClick: () =>
														handleOpenCropDialog(imageCrop?.file as File)
												},
												{
													label: 'to-delete',
													onClick: () => setOpenDeleteCoverDialog(true)
												}
											]}
										/>
									</Box>
								</Box>
							)}

							{!previewImage && (
								<Box sx={styles.dragContainer}>
									{!isDragActive && !isDragReject && !isInvalidImage ? (
										<Box
											{...getRootProps()}
											sx={{
												display: 'flex',
												gap: 1,
												px: 2,
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center'
											}}
											onClick={() => inputRef.current?.click()}
										>
											<Typography sx={styles.coverUploadLabel}>
												{label}
											</Typography>
											<Box
												sx={{
													display: 'flex',
													gap: 2,
													alignItems: 'center',
													justifyContent: 'flex-end'
												}}
											>
												<Tag>{t('optional')}</Tag>
												<ButtonIcon
													icon={<FileUploadIcon />}
													title={t('upload-cover')}
												/>
											</Box>
										</Box>
									) : (
										<HelperMessage
											isDragActive={isDragActive}
											isDragReject={isDragReject}
											isInvalidImage={isInvalidImage}
										/>
									)}
								</Box>
							)}
						</Box>
					)
				}}
			</Dropzone>
			{!isInvalidImage && imageCrop ? (
				<CropImageFullScreen
					aspectRatio={4 / 3}
					url={imageCrop.src}
					file={imageCrop.file}
					open={openCropDialog}
					onCrop={handleUploadImage}
					onClose={() => setOpenCropDialog(false)}
				/>
			) : null}
		</>
	)
}

const styles = {
	borderColorActive: (theme: Theme) => ({
		borderColor: theme.palette.primary.main
	}),
	borderColorReject: {
		borderColor: '#e57878'
	},
	coverUploadLabel: (theme: Theme) => ({
		color: theme.palette.text.secondary,
		fontSize: '0.875rem',
		textAlign: 'center'
	}),
	dragContainer: (theme: Theme) => ({
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		border: '1px dashed ' + theme.palette.grey[300],
		boxSizing: 'content-box',
		borderRadius: '4px',
		cursor: 'pointer',
		gap: 2,
		aspectRatio: 4 / 3,
		width: 180,
		minHeight: 36.5,
		transition: 'height 0.2s ease'
	}),
	dark: {
		backgroundColor: '#212121'
	},
	light: (theme: Theme) => ({
		backgroundColor: '#fff'
	}),
	previewImageBox: (theme: Theme) => ({
		position: 'relative',
		backgroundColor: theme.palette.grey[300],
		borderRadius: '4px',
		width: 180,
		aspectRatio: 4 / 3
	}),
	previewImageContainer: (theme: Theme) => ({
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: '50% 50%',
		borderRadius: '4px',
		cursor: 'pointer',
		aspectRatio: 4 / 3,
		width: 180,
		position: 'relative'
	}),
	textColorActive: (theme: Theme) => ({
		color: theme.palette.primary.main
	}),
	simpleDialogActionsBox: (theme: Theme) => ({
		alignSelf: 'end',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		justifyContent: 'end',
		mt: 2
	})
}

export default CoverUpload
