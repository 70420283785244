import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import {Theme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {PagarmeLogo} from '../../../../components/logos/PagarmeLogo'

import {Dialog} from '../../../../components/Dialog'
import {Status} from '../../../../components/Status'

const ServiceFees = () => {
	const {t} = useTranslation()

	return (
		<Box sx={styles.detailsItemBox}>
			<Typography variant="h6">{t('service-fees')}</Typography>
			<Box sx={styles.detailsItemBox2}>
				<Box>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('service-fee-processing')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{'R$ 0,40'}
					</Typography>
				</Box>
				<Box>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('service-fee-transfer')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{'R$ 3,67'}
					</Typography>
				</Box>
				<Box>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('service-fee-anticipation')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{'2,89% ' + t('service-fee-anticipation-per-installment')}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

const TicketAndPixFees = () => {
	const {t} = useTranslation()

	return (
		<Box sx={styles.detailsItemBox}>
			<Typography variant="h6">{t('ticket-payment-and-pix-fees')}</Typography>
			<Box sx={styles.detailsItemBox2}>
				<Box>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('ticket-payment')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{'R$ 3,49'}
					</Typography>
				</Box>
				<Box>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('pix')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{'0,99%'}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

const CardBrandFees = () => {
	const {t} = useTranslation()

	const fees = [
		{
			brand: 'Visa',
			installments: '1x',
			fee: '3,19%'
		},
		{
			brand: 'Visa',
			installments: '2 - 6x',
			fee: '4,49%'
		},
		{
			brand: 'Visa',
			installments: '7 - 12x',
			fee: '4,99%'
		},
		{
			brand: 'Mastercard',
			installments: '1x',
			fee: '3,19%'
		},
		{
			brand: 'Mastercard',
			installments: '2 - 6x',
			fee: '4,49%'
		},
		{
			brand: 'Mastercard',
			installments: '7 - 12x',
			fee: '4,99%'
		},
		{
			brand: 'Elo',
			installments: '1x',
			fee: '3,19%'
		},
		{
			brand: 'Elo',
			installments: '2 - 6x',
			fee: '4,98%'
		},
		{
			brand: 'Elo',
			installments: '7 - 12x',
			fee: '6,70%'
		},
		{
			brand: 'Hipercard',
			installments: '1x',
			fee: '3,19%'
		},
		{
			brand: 'Hipercard',
			installments: '2 - 6x',
			fee: '4,98%'
		},
		{
			brand: 'Hipercard',
			installments: '7 - 12x',
			fee: '6,70%'
		},
		{
			brand: 'Amex',
			installments: '1x',
			fee: '3,19%'
		},
		{
			brand: 'Amex',
			installments: '2 - 6x',
			fee: '4,98%'
		},
		{
			brand: 'Amex',
			installments: '7 - 12x',
			fee: '6,70%'
		}
	]

	return (
		<Box sx={styles.detailsItemBox}>
			<Typography variant="h6">{t('card-brand-fees')}</Typography>
			<Card variant="outlined" sx={{display: 'grid'}}>
				<TableContainer>
					<Table sx={{minWidth: 800}}>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography variant="body2" sx={[{fontWeight: 500}]}>
										{t('brand')}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2" sx={[{fontWeight: 500}]}>
										{t('installments')}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2" sx={[{fontWeight: 500}]}>
										{t('fee')}
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{fees.map((fee, index) => (
								<TableRow key={index} sx={{border: 'none !important'}}>
									<TableCell
										component="th"
										scope="row"
										sx={{
											borderBottom: index === fees.length - 1 ? 'none' : null
										}}
									>
										<Typography variant="body2" sx={styles.detailsText}>
											{fee.brand}
										</Typography>
									</TableCell>
									<TableCell
										component="th"
										scope="row"
										sx={{
											borderBottom: index === fees.length - 1 ? 'none' : null
										}}
									>
										<Typography variant="body2" sx={styles.detailsText}>
											{fee.installments}
										</Typography>
									</TableCell>
									<TableCell
										component="th"
										scope="row"
										sx={{
											borderBottom: index === fees.length - 1 ? 'none' : null
										}}
									>
										<Typography variant="body2" sx={styles.detailsText}>
											{fee.fee}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Card>
		</Box>
	)
}

const ReceiptDeadlines = () => {
	const {t} = useTranslation()

	return (
		<Box sx={styles.detailsItemBox}>
			<Typography variant="h6">{t('receipt-deadlines')}</Typography>
			<Box sx={styles.detailsItemBox2}>
				<Box sx={{maxWidth: 210}}>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('credit-card')}
					</Typography>
					<Typography variant="body2" sx={{mb: 0.5}}>
						{t('credit-card-receipt-deadline-info-1')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{t('credit-card-receipt-deadline-info-2')}
					</Typography>
				</Box>
				<Box sx={{maxWidth: 210}}>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('ticket-payment')}
					</Typography>
					<Typography variant="body2" sx={{mb: 0.5}}>
						{t('ticket-payment-receipt-deadline-info-1')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{t('ticket-payment-receipt-deadline-info-2')}
					</Typography>
				</Box>
				<Box sx={{maxWidth: 210}}>
					<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
						{t('pix')}
					</Typography>
					<Typography variant="body2" sx={{mb: 0.5}}>
						{t('pix-receipt-deadline-info-1')}
					</Typography>
					<Typography variant="body2" sx={styles.detailsText}>
						{t('pix-receipt-deadline-info-2')}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

interface PagarmeFeesDialogProps {
	open: boolean
	onClose: () => void
}

export const PagarmeFeesDialog = ({open, onClose}: PagarmeFeesDialogProps) => {
	return (
		<Dialog.Root open={open} maxWidth={900}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>
					<PagarmeLogo />
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body width="100%">
				<Box sx={styles.detailsContentBox}>
					<ServiceFees />
					<TicketAndPixFees />
					<CardBrandFees />
					<ReceiptDeadlines />
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}

const styles = {
	detailsItemBox: {
		display: 'flex',
		gap: 1,
		flexDirection: 'column'
	},
	detailsItemBox2: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 5
	},
	detailsSubtitle: (theme: Theme) => ({
		mb: 0.5
	}),
	detailsText: (theme: Theme) => ({
		color: theme.palette.grey[600]
	}),
	detailsTitleBox: (theme: Theme) => ({
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		gap: 3,
		py: 2,
		px: 3
	}),
	detailsContentBox: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column',
		gap: 3,
		p: 3
	}),
	header: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		alignItems: 'center'
	}
}
