import {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {Theme, useTheme} from '@mui/material/styles'

import {HiOutlineDotsHorizontal as DotsIcon} from 'react-icons/hi'
import {MdOutlineFilterList as FilterListIcon} from 'react-icons/md'
import {FaCircle as CircleIcon} from 'react-icons/fa'
import {BiSolidPurchaseTag as TagIcon} from 'react-icons/bi'

import useAppStates from '../../../stores/useAppStates'
import {price} from '../../../helpers/price'
import {Status, getStatusProps} from '../../Status'
import SaleDetailsDialog from './DetailsDialog'
import {Divider, Icon, TableFooter} from '@mui/material'
import {TableRowEmpty} from '../../table/TableRowEmpty'
import useSaleStates from '../../../stores/useSaleStates'
import {date} from '../../../helpers/date'
import Tag from '../../typography/Tag'
import {useMutation} from '@tanstack/react-query'
import {deleteSale as deleteSaleFetch} from '../../../api/sales'
import {ConfirmDialog} from '../../dialogs/ConfirmDialog'
import AlertDialog from '../../AlertDialog'
import {ExportSalesToCSV} from './ExportSalesToCSV'
import {SaleFilters} from '../../../pages/Sales'

const TableRowsSkeleton = ({length}: {length: number}) => {
	const tableRows = []

	for (let i = 0; i < length; i++) {
		tableRows.push(
			<TableRow key={'sale-row-skeleton-' + i}>
				<TableCell component="th" scope="row">
					<Box
						sx={theme => ({
							display: 'flex',
							gap: 2,
							alignItems: 'center',
							width: '100%',
							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column',
								alignItems: 'flex-start'
							}
						})}
					>
						<Box
							sx={{
								height: 50,
								aspectRatio: '4/3',
								backgroundColor: 'grey.100',
								borderRadius: '4px'
							}}
						/>
						<Box>
							<Skeleton variant="text" width={180} />
							<Skeleton variant="text" width={130} />
						</Box>
					</Box>
				</TableCell>
				<TableCell align="left">
					<Skeleton variant="text" width={130} />
					<Skeleton variant="text" width={120} />
				</TableCell>
				<TableCell align="left">
					<Skeleton variant="text" width={90} />
					<Skeleton variant="text" width={32} />
				</TableCell>
				<TableCell align="left">
					<Skeleton variant="text" width={70} />
				</TableCell>
				<TableCell align="left">
					<Status.Root>
						<Skeleton width={80} height={32} />
					</Status.Root>
				</TableCell>
				<TableCell align="left">
					<IconButton size="small">
						<DotsIcon></DotsIcon>
					</IconButton>
				</TableCell>
			</TableRow>
		)
	}

	return <>{tableRows}</>
}

interface SalesListProps {
	firstLoad: boolean
	isLoading: boolean
	limit: number
	page: number
	filters?: SaleFilters
	offset?: number
	setFilters: (filters?: {status?: string}) => void
	setLimit: (limit: number) => void
	setOffset: (offset: number) => void
	setPage: (page: number) => void
	handleChangeURL: (page: number) => void
}

const SalesList = ({
	firstLoad,
	isLoading,
	limit,
	offset,
	page,
	filters,
	setFilters,
	setLimit,
	setOffset,
	setPage,
	handleChangeURL
}: SalesListProps) => {
	const {currentLang} = useAppStates()
	const {salesList, total, setSalesList, deleteSale} = useSaleStates()
	const [anchorElStatusFilterList, setAnchorElStatusFilterList] =
		useState<null | HTMLElement>(null)
	const [anchorElActionList, setAnchorElActionList] =
		useState<null | HTMLElement>(null)
	const [statusFilter, setStatusFilter] = useState<string>('all')
	const [saleOpenedId, setSaleOpenedId] = useState<string>()
	const [openSaleDetails, setOpenSaleDetails] = useState(false)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const [alertMessage, setOpenAlertMessage] = useState('')
	const openStatusFilterList = Boolean(anchorElStatusFilterList)
	const openActionList = Boolean(anchorElActionList)

	const theme = useTheme()
	const {t} = useTranslation()

	const headCells: {
		id: string
		align: 'left' | 'right'
		label: string
		width?: number | string
		minWidth?: number
	}[] = [
		{
			id: 'experience',
			align: 'left',
			label: 'experience',
			minWidth: 300,
			width: '100%'
		},
		{
			id: 'customer',
			align: 'left',
			label: 'customer',
			minWidth: 250
		},
		{id: 'date', align: 'left', label: 'date', minWidth: 150},
		{
			id: 'amount',
			align: 'left',
			label: 'amount',
			width: 150,
			minWidth: 150
		},
		{
			id: 'status',
			align: 'left',
			label: 'status',
			width: 140,
			minWidth: 150
		},
		{id: 'actions', align: 'right', label: 'actions', minWidth: 80}
	]

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
		setOffset(newPage * limit)
		handleChangeURL(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(+event.target.value)
		setOffset(0)
		setPage(0)
	}

	const handleClickStatusFilterList = (
		event: React.MouseEvent<HTMLElement>
	) => {
		setAnchorElStatusFilterList(event.currentTarget)
	}

	const handleCloseStatusFilterList = () => {
		setAnchorElStatusFilterList(null)
	}

	const handleClickActionList = (
		event: React.MouseEvent<HTMLElement>,
		saleId: string
	) => {
		setSaleOpenedId(saleId)
		setAnchorElActionList(event.currentTarget)
	}

	const handleCloseActionList = () => {
		setAnchorElActionList(null)
	}

	const handleChangeStatusFilter = (status: string) => {
		setStatusFilter(status)
		setFilters({
			status: status === 'all' ? undefined : status
		})
		handleCloseStatusFilterList()
	}

	const handleOpenSaleDetails = () => {
		setOpenSaleDetails(true)
	}

	const handleCloseSaleDetails = () => {
		setOpenSaleDetails(false)
	}

	const saleFocused = useMemo(
		() => salesList.find(sale => sale.id === saleOpenedId),
		[salesList, saleOpenedId]
	)

	const {mutate: mutateDelete, isLoading: isDeleting} = useMutation(
		deleteSaleFetch,
		{
			networkMode: 'always'
		}
	)

	const handleDeleteSale = useCallback(() => {
		if (saleFocused && !isDeleting) {
			deleteSale(saleFocused.id)
			mutateDelete(
				{id: saleFocused.id},
				{
					onSuccess: ({responseStatusCode, error}) => {
						if (responseStatusCode !== 200) {
							setOpenAlertDialog(true)
							setOpenAlertMessage(error?.message || t('error-server-default'))
							setSalesList(salesList)
						}
					},
					onError: error => {
						console.error(error)
					}
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saleFocused, isDeleting])

	const menuList = useMemo(
		() =>
			[
				{
					key: 'view-details',
					label: t('view-details'),
					onClick: handleOpenSaleDetails
				},
				{
					key: 'delete',
					label: t('to-delete'),
					onClick: () => setOpenConfirmDialog(true)
				}
			].filter(item => item.key !== 'delete' || saleFocused?.status === 'open'),
		[saleFocused, t]
	)

	const menuDevList = [
		{
			key: 'view-details',
			label: t('view-details'),
			onClick: handleOpenSaleDetails
		},
		{
			key: 'delete',
			label: t('to-delete'),
			onClick: () => setOpenConfirmDialog(true)
		}
	].filter(item => item.key !== 'delete' || saleFocused?.status === 'open')

	return (
		<>
			<AlertDialog
				open={openAlertDialog}
				onClose={() => setOpenAlertDialog(false)}
				message={alertMessage}
				severity="error"
			/>
			<ConfirmDialog
				open={openConfirmDialog}
				onClose={() => setOpenConfirmDialog(false)}
				title={t('confirm-delete-sale-1')}
				confirmText={t('to-delete')}
				contentMessage={t('confirm-delete-sale-2')}
				onConfirm={handleDeleteSale}
			/>
			{saleOpenedId ? (
				<SaleDetailsDialog
					id={saleOpenedId}
					open={openSaleDetails}
					onClose={handleCloseSaleDetails}
				/>
			) : null}
			<Menu
				anchorEl={anchorElActionList}
				id="action-list"
				open={openActionList}
				onClose={handleCloseActionList}
				onClick={handleCloseActionList}
				PaperProps={{
					elevation: 0,
					sx: theme => ({
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						bgcolor:
							theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor:
								theme.palette.mode === 'light' ? 'background.paper' : 'grey.50',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					})
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top'
				}}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom'
				}}
			>
				{process.env.REACT_APP_ENVIRONMENT !== 'production'
					? menuDevList.map(
							({
								key,
								label,
								onClick
							}: {
								key: string
								label: string
								onClick: () => void
							}) => {
								return (
									<Box key={'sale-menu-list-' + key}>
										{key === 'edit' ? (
											<>
												<Divider sx={{my: 0.5}} />
												<Typography
													variant="body2"
													sx={{
														color: 'text.secondary',
														px: 2,
														pt: 0.75,
														pb: 1.75
													}}
												>
													{t('dev-only')}
												</Typography>
											</>
										) : null}
										<MenuItem
											key={key}
											onClick={onClick}
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 0.5,
												backgroundColor:
													key === statusFilter
														? 'rgba(0, 0, 0, 0.03)'
														: 'transparent'
											}}
										>
											<Typography variant="body2">{label}</Typography>
										</MenuItem>
									</Box>
								)
							}
					  )
					: menuList.map(
							({
								key,
								label,
								onClick
							}: {
								key: string
								label: string
								onClick: () => void
							}) => {
								return (
									<MenuItem
										key={'sale-menu-item-' + key}
										onClick={onClick}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 0.5,
											backgroundColor:
												key === statusFilter
													? 'rgba(0, 0, 0, 0.03)'
													: 'transparent'
										}}
									>
										<Typography variant="body2">{label}</Typography>
									</MenuItem>
								)
							}
					  )}
			</Menu>
			<Paper variant="outlined" sx={{display: 'grid'}}>
				<TableContainer>
					<Table sx={{minWidth: 800}}>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell
										key={headCell.id}
										align={headCell.align}
										width={headCell.width}
										sx={{
											minWidth: headCell.minWidth
										}}
									>
										<Box sx={{display: 'flex', alignItems: 'center'}}>
											<Typography variant="body2" sx={[{fontWeight: 500}]}>
												{t(headCell.label)}
											</Typography>
											{headCell.id === 'status' && (
												<Box>
													<Tooltip
														title={t('filter-list')}
														placement="bottom"
														arrow
													>
														<IconButton
															onClick={handleClickStatusFilterList}
															size="small"
															sx={{ml: 1}}
															aria-controls={
																openStatusFilterList
																	? t('filter-list')
																	: undefined
															}
															aria-haspopup="true"
															aria-expanded={
																openStatusFilterList ? 'true' : undefined
															}
														>
															<FilterListIcon fontSize={16} />
														</IconButton>
													</Tooltip>
													<Menu
														anchorEl={anchorElStatusFilterList}
														id="status-filter-list"
														open={openStatusFilterList}
														onClose={handleCloseStatusFilterList}
														onClick={handleCloseStatusFilterList}
														PaperProps={{
															elevation: 0,
															sx: theme => ({
																overflow: 'visible',
																filter:
																	'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
																bgcolor:
																	theme.palette.mode === 'light'
																		? 'background.paper'
																		: 'grey.50',
																mt: 1.5,
																'& .MuiAvatar-root': {
																	width: 32,
																	height: 32,
																	ml: -0.5,
																	mr: 1
																},
																'&:before': {
																	content: '""',
																	display: 'block',
																	position: 'absolute',
																	top: 0,
																	right: 14,
																	width: 10,
																	height: 10,
																	bgcolor:
																		theme.palette.mode === 'light'
																			? 'background.paper'
																			: 'grey.50',
																	transform: 'translateY(-50%) rotate(45deg)',
																	zIndex: 0
																}
															})
														}}
														transformOrigin={{
															horizontal: 'right',
															vertical: 'top'
														}}
														anchorOrigin={{
															horizontal: 'right',
															vertical: 'bottom'
														}}
													>
														{[
															{
																key: 'all'
															},
															{
																key: 'open'
															},
															{
																key: 'paid'
															},
															{
																key: 'pending'
															},
															{
																key: 'failed'
															},
															{
																key: 'overdue'
															},
															{
																key: 'refunded'
															},
															{
																key: 'partial-refunded'
															},
															{
																key: 'processing-refund'
															},
															{
																key: 'awaiting-customer-data-for-refund'
															}
														].map(item => {
															const {color} = getStatusProps(item.key, theme)

															return (
																<MenuItem
																	key={'sale-list-filter-status-' + item.key}
																	onClick={() => {
																		handleChangeStatusFilter(item.key)
																	}}
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: 0.5,
																		backgroundColor:
																			item.key === statusFilter
																				? 'rgba(0, 0, 0, 0.03)'
																				: 'transparent'
																	}}
																>
																	<CircleIcon
																		color={color}
																		style={{marginRight: '4px'}}
																		fontSize={10}
																	/>
																	<Typography variant="body2">
																		{t('status-tag-' + item.key)}
																	</Typography>
																</MenuItem>
															)
														})}
													</Menu>
												</Box>
											)}
										</Box>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(firstLoad || isLoading) && <TableRowsSkeleton length={limit} />}
							{salesList.length === 0 && !firstLoad && !isLoading && (
								<TableRowEmpty
									message={
										statusFilter === 'all'
											? t('no-sales-yet')
											: t('no-sales-results-with-filter')
									}
								/>
							)}
							{!isLoading &&
								salesList
									.map(row => (
										<TableRow key={'sale-row-' + row.id}>
											<TableCell align="left">
												<Box
													sx={theme => ({
														display: 'flex',
														gap: 2,
														alignItems: 'center',
														[theme.breakpoints.down('sm')]: {
															flexDirection: 'column',
															alignItems: 'flex-start'
														}
													})}
												>
													{row.edition.experience?.cover ? (
														<Box
															sx={{
																height: 50,
																aspectRatio: '4/3',
																backgroundColor: 'grey.100',
																...(row.edition.experience.cover
																	? {
																			backgroundImage: `url(${row.edition.experience.cover})`
																	  }
																	: {}),
																backgroundSize: 'cover',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																borderRadius: '4px'
															}}
														/>
													) : (
														<Box
															sx={{
																height: 50,
																aspectRatio: '4/3',
																backgroundColor: 'grey.100',
																borderRadius: '4px',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<Icon
																fontSize="large"
																sx={{color: 'grey.300', mb: 1, ml: 0.5}}
															>
																<TagIcon />
															</Icon>
														</Box>
													)}
													<Box>
														<Typography variant="body2">
															{row.edition.experience.title}
															{row.totalItems > 1 && (
																<Tag
																	sx={{
																		display: 'inline',
																		ml: '4px',
																		borderRadius: '8px',
																		py: 0.25,
																		textWrap: 'nowrap'
																	}}
																>
																	{row.totalItems === 2
																		? t('total-items-unique', {
																				total: row.totalItems - 1
																		  })
																		: t('total-items', {
																				total: row.totalItems - 1
																		  })}
																</Tag>
															)}
														</Typography>
														<Typography variant="caption" sx={style.headCell}>
															{isLoading ? (
																<Skeleton width={230} />
															) : (
																row.edition.title
															)}
														</Typography>
													</Box>
												</Box>
											</TableCell>
											<TableCell align="left">
												<Typography variant="body2">
													{row?.buyer?.name}
												</Typography>
												<Typography variant="caption" sx={style.headCell}>
													{row?.buyer?.email}
												</Typography>
											</TableCell>
											<TableCell component="th" scope="row">
												<Typography variant="body2">
													{date.convertISOToLocation(
														row.createdAt,
														currentLang
													)}
												</Typography>
												<Typography variant="caption" sx={style.headCell}>
													{date.getTime(row.createdAt, currentLang)}
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography variant="body2">
													{price.full(row.totalAmount)}
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Status.Root>
													{
														<Status.Tag type={row.status}>
															<Status.Label
																label={t('status-tag-' + row.status)}
															/>
														</Status.Tag>
													}
												</Status.Root>
											</TableCell>
											<TableCell>
												<Tooltip
													title={t('view-actions')}
													placement="bottom"
													arrow
												>
													<IconButton
														onClick={event => {
															handleClickActionList(event, row.id)
														}}
														size="small"
														aria-controls={
															openActionList ? t('actions') : undefined
														}
														aria-haspopup="true"
														aria-expanded={openActionList ? 'true' : undefined}
													>
														<DotsIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))
									.slice(0, limit)}
						</TableBody>
					</Table>
				</TableContainer>
				{salesList.length > 0 && (
					<TableFooter
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'space-between',
							pl: 2,
							py: 1,
							columnGap: 2,
							rowGap: 1
						}}
					>
						<ExportSalesToCSV offset={offset} limit={limit} filters={filters} />
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={total}
							rowsPerPage={limit}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={t('items-per-page')}
							labelDisplayedRows={({from, to}) =>
								t('list-filter-info', {
									from,
									to
								})
							}
						/>
					</TableFooter>
				)}
			</Paper>
		</>
	)
}

const style = {
	headCell: (theme: Theme) => ({
		color: theme.palette.grey[600]
	}),
	formBox: (theme: Theme) => ({
		mx: 'auto',
		padding: 5,
		width: '80%',
		maxWidth: '600px',
		[theme.breakpoints.down('md')]: {
			my: 0,
			padding: 3,
			width: '100%'
		}
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	})
}

export default SalesList
