import {create} from 'zustand'
import {AsaasSubAccountDocument} from '../api/payment-service/asaas'
import {PS_ASAAS_FEES} from '../config'

export type DocumentKey =
	| 'identification-and-selfie'
	| 'social-contract'
	| 'entrepreneur-requirement'
	| 'minutes-of-election'
	| 'custom'

type BankAccount = {
	accountType: 'checking' | 'savings'
	accountNumber: string
	accountCheckDigit: string
	bankCode: string
	branchNumber: string
	branchCheckDigit?: string
}

type AsaasConfig = {
	companyType: string
	incomeValue: number
	generalStatus: string
	commercialStatus: string
	documentStatus: string
	onboardingStep: number
	bankAccount?: BankAccount
	documents?: {
		'identification-and-selfie'?: AsaasSubAccountDocument
		'social-contract'?: AsaasSubAccountDocument
		'entrepreneur-requirement'?: AsaasSubAccountDocument
		'minutes-of-election'?: AsaasSubAccountDocument
		custom?: AsaasSubAccountDocument
	}
}

type Transfer = {
	id: string
	amount: number
	operationType: string
	feeAmount?: number
	status: string
	createdAt: string
	bankAccount?: {
		accountType: 'checking' | 'savings'
		accountNumber: string
		accountCheckDigit: string
		bankCode: string
		branchNumber: string
	}
}

export interface AsaasFees {
	payment: {
		bankSlip: {
			defaultValue: number
			discountValue: number
			expirationDate: string
			daysToReceive: number
		}
		creditCard: {
			operationValue: number
			oneInstallmentPercentage: number
			upToSixInstallmentsPercentage: number
			upToTwelveInstallmentsPercentage: number
			discountOneInstallmentPercentage: number
			discountUpToSixInstallmentsPercentage: number
			discountUpToTwelveInstallmentsPercentage: number
			hasValidDiscount: boolean
			daysToReceive: number
			discountExpiration: string
		}
		debitCard: {
			operationValue: number
			defaultPercentage: number
			daysToReceive: number
		}
		pix: {
			fixedFeeValue: number
			fixedFeeValueWithDiscount: number
			percentageFee: number | null
			minimumFeeValue: number | null
			maximumFeeValue: number | null
			discountExpiration: string
			type: string
			monthlyCreditsWithoutFee: number
			creditsReceivedOfCurrentMonth: number
		}
	}
	transfer: {
		monthlyTransfersWithoutFee: number
		ted: {
			feeValue: number
			consideredInMonthlyTransfersWithoutFee: boolean
		}
		pix: {
			feeValue: number
			discountValue: null
			expirationDate: null
			consideredInMonthlyTransfersWithoutFee: true
		}
	}
	asaasCard: {
		debit: {
			requestFeeValue: number
			deniedReasons: null
			nationalCashWithdrawalFeeValue: number
			internationalCashWithdrawalProcessingFeePercentage: number
			internationalCashWithdrawalExchangeFeeValue: number
			internationalPurchaseWithdrawalFeePercentage: number
		}
		prepaid: {
			requestFeeValue: number
			deniedReasons: null
			nationalCashWithdrawalFeeValue: number
			internationalCashWithdrawalProcessingFeePercentage: number
			internationalCashWithdrawalExchangeFeeValue: number
			internationalPurchaseWithdrawalFeePercentage: number
		}
		credit: {
			requestFeeValue: number
			deniedReasons: null
			nationalCashWithdrawalFeeValue: number
			internationalCashWithdrawalProcessingFeePercentage: number
			internationalCashWithdrawalExchangeFeeValue: number
			internationalPurchaseWithdrawalFeePercentage: number
		}
	}
	notification: {
		phoneCallFeeValue: number
		whatsAppFeeValue: number
		messagingFeeValue: number
		postalServiceFeeValue: number
		smsFeeValue: number
	}
	creditBureauReport: {
		naturalPersonFeeValue: number
		legalPersonFeeValue: number
	}
	invoice: {
		feeValue: number
	}
	anticipation: {
		bankSlip: {
			monthlyFeePercentage: number
		}
		creditCard: {
			detachedMonthlyFeeValue: number
			installmentMonthlyFeeValue: number
		}
	}
	bill: {
		utilityFeeValue: number
	}
	childAccount: {
		creationFeeValue: number
	}
}

export interface AsaasStates {
	onboardingStep: number
	onboardingCompleted: boolean
	asaasFees: AsaasFees
	asaasConfig: AsaasConfig | null
	asaasCardAlertError: {
		open: boolean
		message: string
	}
	asaasBalance: {
		availableBalance: number
		receivableBalance: number
	}
	asaasBankAccount: BankAccount | null
	asaasTransfers: Transfer[]
	setAsaasFees: (asaasFees: AsaasStates['asaasFees']) => void
	setAsaasConfig: (asaasConfig: AsaasConfig) => void
	setAsaasCardAlertError: (
		asaasCardAlertError: AsaasStates['asaasCardAlertError']
	) => void
	setOnboardingStep: (onboardingStep: number) => void
	updateDocumentStatus: (
		documentKey: DocumentKey,
		newStatus: AsaasSubAccountDocument['status']
	) => void
	updateAsaasSubAccountStatus: (data: {
		commercialStatus: string
		documentStatus: string
		general: string
	}) => void
	setAsaasBalance: (asaasBalance: {
		availableBalance?: number
		receivableBalance?: number
	}) => void
	setAsaasBankAccount: (asaasBankAccount: BankAccount) => void
	setAsaasTransfers: (transfers: Transfer[]) => void
	addAsaasTransfers: (transfers: Transfer[]) => void
	addAsaasTransfer: (transfer: Transfer) => void
	resetAsaasStates: () => void
}

const useAsaasStates = create<AsaasStates>()(set => ({
	onboardingStep: 0,
	onboardingCompleted: false,
	asaasFees: PS_ASAAS_FEES,
	asaasConfig: null,
	asaasCardAlertError: {
		open: false,
		message: ''
	},
	asaasBalance: {
		availableBalance: -1,
		receivableBalance: 0
	},
	asaasBankAccount: null,
	asaasTransfers: [],
	setAsaasFees: asaasFees => set({asaasFees}),
	setAsaasConfig: asaasConfig =>
		set({
			asaasConfig,
			asaasBankAccount: asaasConfig.bankAccount,
			onboardingStep: asaasConfig.onboardingStep,
			onboardingCompleted: asaasConfig.onboardingStep === 3
		}),
	setAsaasCardAlertError: asaasCardAlertError => set({asaasCardAlertError}),
	setOnboardingStep: onboardingStep => set({onboardingStep}),
	updateDocumentStatus: (documentKey, newStatus) => {
		set(state => {
			if (state?.asaasConfig && state.asaasConfig?.documents) {
				return {
					...state,
					asaasConfig: {
						...state.asaasConfig,
						documents: {
							...state.asaasConfig.documents,
							[documentKey]: {
								...state.asaasConfig.documents[documentKey],
								status: newStatus
							}
						}
					}
				}
			}

			return state
		})
	},
	updateAsaasSubAccountStatus: data => {
		set(state => {
			if (state.asaasConfig) {
				state.asaasConfig.commercialStatus = data.commercialStatus
				state.asaasConfig.documentStatus = data.documentStatus
				state.asaasConfig.generalStatus = data.general
			}

			return state
		})
	},
	setAsaasBalance: asaasBalance =>
		set(state => ({
			asaasBalance: {
				...state.asaasBalance,
				...asaasBalance
			}
		})),
	setAsaasBankAccount: asaasBankAccount => set({asaasBankAccount}),
	setAsaasTransfers: asaasTransfers => set({asaasTransfers}),
	addAsaasTransfers: asaasTransfers =>
		set(state => ({
			asaasTransfers: [...state.asaasTransfers, ...asaasTransfers]
		})),
	addAsaasTransfer: asaasTransfer =>
		set(state => ({asaasTransfers: [asaasTransfer, ...state.asaasTransfers]})),
	resetAsaasStates: () =>
		set({
			asaasConfig: null,
			asaasCardAlertError: {
				open: false,
				message: ''
			},
			asaasBalance: {
				availableBalance: -1,
				receivableBalance: -1
			},
			asaasBankAccount: null,
			asaasTransfers: [],
			onboardingStep: 0,
			onboardingCompleted: false
		})
}))

export default useAsaasStates
