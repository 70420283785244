import {AsaasStates} from './stores/useAsaasStates'

/* ---------- API ---------- */
export let SELLER_AUTH_API: string
export let SELLER_API: string
export let SELLER_EXPERIENCES_API: string
export let SELLER_SALES_API: string
export let CHECKOUT_PAGARME_API: string
export let PS_ASAAS_API: string
export let SITE_URL: string
export let PUBLIC_SITE_URL: string
export let CHECKOUT_URL: string
export let WEBHOOKS_BASE_API: string

let BASE_URL: string

switch (process.env.REACT_APP_ENVIRONMENT) {
	case 'local':
		BASE_URL = 'https://localhost'
		SELLER_AUTH_API = BASE_URL + ':3331/seller/auth'
		SELLER_API = BASE_URL + ':3332/seller'
		SELLER_EXPERIENCES_API = BASE_URL + ':3333/seller/experiences'
		SELLER_SALES_API = BASE_URL + ':3335/seller/sales'
		CHECKOUT_PAGARME_API = BASE_URL + ':3336/seller/checkout-pagarme'
		PS_ASAAS_API = BASE_URL + ':3338/seller/payment-service-2'
		WEBHOOKS_BASE_API = BASE_URL + ':3340/webhooks'
		SITE_URL = 'http://localhost:3000'
		PUBLIC_SITE_URL = 'http://localhost:3001'
		CHECKOUT_URL = 'http://localhost:3002'
		break
	case 'dev':
		BASE_URL = '.excofy.workers.dev'
		SELLER_AUTH_API = 'https://seller-dev' + BASE_URL
		SELLER_API = 'https://seller-auth-dev' + BASE_URL
		SELLER_EXPERIENCES_API = 'https://experiences-dev' + BASE_URL
		SELLER_SALES_API = 'https://sales-dev' + BASE_URL
		break
	case 'staging':
		BASE_URL = 'https://staging-api.excofy.com'
		SELLER_AUTH_API = BASE_URL + '/seller/auth'
		SELLER_API = BASE_URL + '/seller'
		SELLER_EXPERIENCES_API = BASE_URL + '/seller/experiences'
		SELLER_SALES_API = BASE_URL + '/seller/sales'
		CHECKOUT_PAGARME_API = BASE_URL + '/seller/checkout-pagarme'
		PS_ASAAS_API = BASE_URL + '/seller/payment-service-2'
		WEBHOOKS_BASE_API = BASE_URL + '/webhooks'
		SITE_URL = 'https://staging-dashboard.excofy.com'
		PUBLIC_SITE_URL = 'https://staging-site.excofy.com'
		CHECKOUT_URL = 'https://staging-checkout.excofy.com'
		break
	default:
		BASE_URL = 'https://api.excofy.com'
		SELLER_AUTH_API = BASE_URL + '/seller/auth'
		SELLER_API = BASE_URL + '/seller'
		SELLER_EXPERIENCES_API = BASE_URL + '/seller/experiences'
		SELLER_SALES_API = BASE_URL + '/seller/sales'
		CHECKOUT_PAGARME_API = BASE_URL + '/seller/checkout-pagarme'
		PS_ASAAS_API = BASE_URL + '/seller/payment-service-2'
		WEBHOOKS_BASE_API = BASE_URL + '/webhooks'
		SITE_URL = 'https://dashboard.excofy.com'
		PUBLIC_SITE_URL = 'https://site.excofy.com'
		CHECKOUT_URL = 'https://checkout.excofy.com'
		break
}

/* ---------- Language ---------- */
export const VALID_LANG: {[key: string]: string} = {
	'pt-BR': 'pt',
	pt: 'pt',
	en: 'en',
	'en-US': 'en'
}
export const DEFAULT_LANG = 'pt'

/* ---------- Image Validations ---------- */
export const IMAGE = {
	avatarTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
	coverTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
	logoTypes: ['image/png'],
	sectionImageTypes: ['image/png', 'image/jpeg', 'image/jpg'],
	max_size: 10000000, // 10MB
	aspectRatio: 8.3,
	dimensions: {
		min: {width: 75, height: 75},
		max: {width: 3000, height: 3000}
	},
	experienceCover: {
		dimensions: {
			min: {width: 400, height: 300},
			max: {width: 3000, height: 3000}
		},
		max_size: 20000000 // 20MB
	}
}

/* ---------- Experience Validations ---------- */
export const INPUT = {
	EXPERIENCE: {
		description: {
			max: 500,
			min: 5
		},
		slug: {
			max: 120,
			min: 5
		},
		title: {
			max: 100,
			min: 5
		}
	},
	EDITION: {
		description: {
			max: 500,
			min: 5
		},
		slug: {
			max: 120,
			min: 3
		},
		title: {
			max: 100,
			min: 3
		},
		vacancies: {
			min: 0
		}
	},
	SECTION: {
		title: {
			max: 100,
			min: 3
		},
		image: {
			alt: {
				max: 100,
				min: 3
			}
		}
	}
}

/* ---------- Payment Service Fees ---------- */
export const PS_ASAAS_FEES: AsaasStates['asaasFees'] = {
	'payment': {
		'bankSlip': {
			'defaultValue': 1.79,
			'discountValue': 1.79,
			'expirationDate': '2024-06-07 00:00:00',
			'daysToReceive': 1
		},
		'creditCard': {
			'operationValue': 0.29,
			'oneInstallmentPercentage': 2.75,
			'upToTwelveInstallmentsPercentage': 3.37,
			'upToSixInstallmentsPercentage': 3.71,
			'discountOneInstallmentPercentage': 1.99,
			'discountUpToSixInstallmentsPercentage': 2.49,
			'discountUpToTwelveInstallmentsPercentage': 2.99,
			'hasValidDiscount': false,
			'daysToReceive': 32,
			'discountExpiration': '2024-06-07 00:00:00'
		},
		'debitCard': {
			'operationValue': 0.35,
			'defaultPercentage': 1.89,
			'daysToReceive': 3
		},
		'pix': {
			'fixedFeeValue': 0.99,
			'fixedFeeValueWithDiscount': 0.99,
			'percentageFee': null,
			'minimumFeeValue': null,
			'maximumFeeValue': null,
			'discountExpiration': '2024-06-07 00:00:00',
			'type': 'FIXED',
			'monthlyCreditsWithoutFee': 30,
			'creditsReceivedOfCurrentMonth': 0
		}
	},
	'transfer': {
		'monthlyTransfersWithoutFee': 30,
		'ted': {
			'feeValue': 5.0,
			'consideredInMonthlyTransfersWithoutFee': false
		},
		'pix': {
			'feeValue': 2.0,
			'discountValue': null,
			'expirationDate': null,
			'consideredInMonthlyTransfersWithoutFee': true
		}
	},
	'asaasCard': {
		'debit': {
			'requestFeeValue': 20.0,
			'deniedReasons': null,
			'nationalCashWithdrawalFeeValue': 7.6,
			'internationalCashWithdrawalProcessingFeePercentage': 0.745,
			'internationalCashWithdrawalExchangeFeeValue': 1.99,
			'internationalPurchaseWithdrawalFeePercentage': 5.38
		},
		'prepaid': {
			'requestFeeValue': 20.0,
			'deniedReasons': null,
			'nationalCashWithdrawalFeeValue': 7.6,
			'internationalCashWithdrawalProcessingFeePercentage': 0.745,
			'internationalCashWithdrawalExchangeFeeValue': 1.99,
			'internationalPurchaseWithdrawalFeePercentage': 5.38
		},
		'credit': {
			'requestFeeValue': 0.0,
			'deniedReasons': null,
			'nationalCashWithdrawalFeeValue': 7.6,
			'internationalCashWithdrawalProcessingFeePercentage': 0.745,
			'internationalCashWithdrawalExchangeFeeValue': 1.99,
			'internationalPurchaseWithdrawalFeePercentage': 5.38
		}
	},
	'notification': {
		'phoneCallFeeValue': 0.55,
		'whatsAppFeeValue': 0.55,
		'messagingFeeValue': 0.99,
		'postalServiceFeeValue': 2.91,
		'smsFeeValue': 0.5
	},
	'creditBureauReport': {
		'naturalPersonFeeValue': 16.99,
		'legalPersonFeeValue': 16.99
	},
	'invoice': {
		'feeValue': 0.99
	},
	'anticipation': {
		'bankSlip': {
			'monthlyFeePercentage': 5.79
		},
		'creditCard': {
			'detachedMonthlyFeeValue': 2.0,
			'installmentMonthlyFeeValue': 2.49
		}
	},
	'bill': {
		'utilityFeeValue': 1
	},
	'childAccount': {
		'creationFeeValue': 12.9
	}
}

export const PS_EXCOFY_RULES = {
	minimumValueToTransfer: {
		asaas: 5
	}
}

// ----------- Pagarme fees ----------- //
export const PS_FEES_PAGARME = {
	fixedFee: 0.4,
	mdr: {
		'1': 0.0319, // 3.19%
		'2': 0.0449, // 4.98%
		'3': 0.0449, // 4.49%
		'4': 0.0449, // 4.49%
		'5': 0.0449, // 4.49%
		'6': 0.0449, // 4.49%
		'7': 0.0499, // 4.99%
		'8': 0.0499, // 4.99%
		'9': 0.0499, // 4.99%
		'10': 0.0499, // 4.99%
		'11': 0.0499, // 4.99%
		'12': 0.0499 // 4.99%
	},
	boleto: 3.49, // 319 cents
	pix: 0.99, // 99 0.99%
	anticipationMonthlyFee: 0, // 2,49%
	anticipationDailyFee: 0, // 0.083%
	daysToAnticipation: 0
}

// ----------- Asaas fees ----------- //
export const PS_FEES_ASAAS = {
	fixedFee: 0.29,
	mdr: {
		'1': 0.0275, // 2,75 %
		'2': 0.0337, // 3,37 %
		'3': 0.0337, // 3,37 %
		'4': 0.0337, // 3,37 %
		'5': 0.0337, // 3,37 %
		'6': 0.0337, // 3,37 %
		'7': 0.0371, // 3,71 %
		'8': 0.0371, // 3,71 %
		'9': 0.0371, // 3,71 %
		'10': 0.0371, // 3,71 %
		'11': 0.0371, // 3,71 %
		'12': 0.0371 // 3,71 %
	},
	boleto: 1.79, // 179 cents
	pix: 0.99, // 99 cents,
	anticipationMonthlyFee: 0.0229, // 2,29%
	anticipationDailyFee: 0.0007633, // 0.07633%
	daysToAnticipation: 33
}

export const EXCOFY_FEE = 0.03

export const PS_FEES = {
	asaas: PS_FEES_ASAAS,
	pagarme: PS_FEES_PAGARME
}
