import {DEFAULT_LANG, SELLER_EXPERIENCES_API} from '../config'
import {Edition} from '../entities/Edition'
import {authenticatedFetch} from '../helpers/authenticatedFetch'
import useAppStates from '../stores/useAppStates'
import useAuthStates from '../stores/useAuthStates'

interface Message {
	message: string
}

interface ListEditionsMainInfoByExperienceResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: {
		editions: {
			id: string
			title: string
			status: string
		}[]
	}
	responseStatusCode?: number
}

export const listEditionsMainInfoByExperience = async ({
	experienceId
}: {
	experienceId: string
}): Promise<ListEditionsMainInfoByExperienceResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/editions/main-info?experience_id=${experienceId}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListEditionsByExperienceResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
	}
	data?: {
		editions: Edition[]
		moreResults: boolean
	}
	responseStatusCode?: number
}

export const listEditionsByExperience = async ({
	experienceId,
	offset,
	limit
}: {
	experienceId: string
	offset: number
	limit: number
}): Promise<ListEditionsByExperienceResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/editions?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ListEditionBySlugResponse {
	error?: {
		message: string
	}
	data?: Edition
	responseStatusCode: number
}

export const listEditionBySlug = async ({
	slug,
	experienceSlug
}: {
	slug: string
	experienceSlug: string
}): Promise<ListEditionBySlugResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceSlug}/editions/${slug}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CreateEditionResponse {
	success?: {
		message: string
	}
	data?: {
		_id: string
		title: string
		slug: string
		description: string
		status: string
		price: number
		pricingUnit?: string
		vacancies?: number
		remainingVacancies?: number
		startDate: string
		limitDateToSell: string
		updatedAt: string
		passCreditCardFeesToCustomer: boolean
		allowMultipleItems: boolean
		checkoutType: string
	}
	error?: {
		message: string
		fields?: {
			title?: Message[]
			slug?: Message[]
			description?: Message[]
			price?: Message[]
			vacancies?: Message[]
			startDate?: Message[]
			limitDateToSell?: Message[]
		}
	}
	responseStatusCode?: number
}

export const createEdition = async (values: {
	experienceId: string
	title: string
	slug: string
	description: string
	price: number
	vacancies?: number
	startDate: string
	limitDateToSell: string
	unit?: string
	allowMultipleItems?: boolean
	checkoutType: string
	anticipationEnabled?: boolean
	passAnticipationFeesToCustomer?: boolean
	passCreditCardFeesToCustomer?: boolean
}): Promise<CreateEditionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${values.experienceId}/editions`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface UpdateEditionResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			title?: Message[]
			slug?: Message[]
			description?: Message[]
			price?: Message[]
			pricingUnit?: Message[]
			vacancies?: Message[]
			startDate?: Message[]
			limitDateToSell?: Message[]
			passCreditCardFeesToCustomer?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateEdition = async ({
	experienceId,
	editionId,
	...values
}: {
	experienceId: string
	editionId?: string
	title?: string
	slug?: string
	description?: string
	price?: number
	vacancies?: number
	startDate?: string
	limitDateToSell?: string
	unit?: string
	allowMultipleItems?: boolean
	pricingUnit: string | null
	checkoutType?: string
	anticipationEnabled: boolean
	passAnticipationFeesToCustomer: boolean
	passCreditCardFeesToCustomer: boolean
}): Promise<UpdateEditionResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/editions/${editionId}`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface ChangeEditionStatusResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			status?: Message[]
		}
	}
	data?: {status: string}
	responseStatusCode?: number
}

export const changeEditionStatus = async ({
	experienceId,
	editionId,
	status
}: {
	experienceId: string
	editionId: string
	status: string
}): Promise<ChangeEditionStatusResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/editions/${editionId}/status`,
		{
			method: 'PATCH',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({status})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}

interface CheckEditionSlugResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			slug?: Message[]
		}
	}
	data?: {
		slug: string
	}
	responseStatusCode?: number
}

export const checkEditionSlug = async ({
	experienceId,
	slug
}: {
	experienceId: string
	slug: string
}): Promise<CheckEditionSlugResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${SELLER_EXPERIENCES_API}/${experienceId}/editions/slug`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({slug})
		}
	)

	const data = await response.json()

	return {...data, responseStatusCode: response.status}
}
