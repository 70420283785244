import * as yup from 'yup'
import {useState} from 'react'
import {useFormik} from 'formik'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {CircularProgress, Typography} from '@mui/material'
import {updateUpsellIntegration} from '../../../api/integrations'
import AlertMessage from '../../AlertMessage'
import {integrationMapper} from './utils/integrationMapper'
import InfoPopover from '../../InfoPopover'

export const UpsellForm = ({
	metadata,
	id,
	onUpdate
}: {
	metadata?: string
	id: string
	onUpdate: (data: {id: string; status: string; metadata?: string}) => void
}) => {
	const {t} = useTranslation()
	const [errorMessage, setErrorMessage] = useState('')
	const [openAlertError, setOpenAlertError] = useState(false)
	const [showSuccessButton, setShowSuccessButton] = useState(false)

	const {redirectFromInstantPayment, redirectFromPostPayment} =
		integrationMapper.upsell(metadata)

	const handleChangeForm = () => {
		setOpenAlertError(false)
		setShowSuccessButton(false)
	}

	const {mutate, isLoading} = useMutation(updateUpsellIntegration, {
		networkMode: 'always'
	})

	const validationSchema = yup.object({
		redirectFromInstantPayment: yup.string().url(t('error-url-invalid')),
		redirectFromPostPayment: yup.string().url(t('error-url-invalid'))
	})

	const formik = useFormik({
		initialValues: {
			redirectFromInstantPayment,
			redirectFromPostPayment
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			mutate(
				{
					id,
					redirectFromInstantPayment: values?.redirectFromInstantPayment,
					redirectFromPostPayment: values?.redirectFromPostPayment
				},
				{
					onSuccess: ({data, error, responseStatusCode}) => {
						if (responseStatusCode === 200 && data) {
							setShowSuccessButton(true)
							onUpdate(data)
						} else if (error) {
							setErrorMessage(error.message)
							setOpenAlertError(true)
						} else {
							setErrorMessage(t('error-server-default'))
							setOpenAlertError(true)
						}
					},
					onError: error => {
						setErrorMessage(t('error-server-default'))
						setOpenAlertError(true)
					}
				}
			)
		}
	})

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 4
			}}
		>
			{openAlertError && (
				<AlertMessage
					severity="error"
					message={errorMessage}
					open={openAlertError}
					onClose={() => setOpenAlertError(false)}
					sx={{
						'& .MuiPaper-root': {
							mt: '0px !important'
						}
					}}
				/>
			)}
			<Box
				onSubmit={formik.handleSubmit}
				onChange={handleChangeForm}
				component="form"
				autoComplete="off"
				sx={theme => ({
					mt: 0,
					pt: 0,
					width: '100%'
				})}
			>
				<Stack direction="column" spacing={4}>
					<TextField
						name="redirectFromInstantPayment"
						type="text"
						label={t('redirect-from-instant-payment')}
						variant="outlined"
						helperText={
							formik.touched.redirectFromInstantPayment &&
							formik.errors.redirectFromInstantPayment
						}
						error={
							formik.touched.redirectFromInstantPayment &&
							Boolean(formik.errors.redirectFromInstantPayment)
						}
						value={formik.values.redirectFromInstantPayment}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
						sx={{
							width: 'calc(100% - 34px)'
						}}
						InputProps={{
							endAdornment: (
								<InfoPopover
									sx={theme => ({
										position: 'absolute',
										right: -34,
										[theme.breakpoints.down('sm')]: {
											position: 'relative',
											right: 0
										}
									})}
								>
									<Typography variant="body2">
										{t('info-redirect-from-instant-payment')}
									</Typography>
								</InfoPopover>
							)
						}}
					/>
					<TextField
						name="redirectFromPostPayment"
						type="text"
						label={t('redirect-from-post-payment')}
						variant="outlined"
						helperText={
							formik.touched.redirectFromPostPayment &&
							formik.errors.redirectFromPostPayment
						}
						error={
							formik.touched.redirectFromPostPayment &&
							Boolean(formik.errors.redirectFromPostPayment)
						}
						value={formik.values.redirectFromPostPayment}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						size="small"
						sx={{
							width: 'calc(100% - 34px)'
						}}
						InputProps={{
							endAdornment: (
								<InfoPopover
									sx={theme => ({
										position: 'absolute',
										right: -34,
										[theme.breakpoints.down('sm')]: {
											position: 'relative',
											right: 0
										}
									})}
								>
									<Typography variant="body2">
										{t('info-redirect-from-post-payment')}
									</Typography>
								</InfoPopover>
							)
						}}
					/>
					{showSuccessButton ? (
						<Button
							size="large"
							variant="contained"
							type="button"
							sx={{
								height: '36.5px',
								backgroundColor: 'success.light',
								color: 'success.contrastText',
								'&:hover': {backgroundColor: 'success.light'}
							}}
						>
							{t('saved-changes')}
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							sx={{
								height: '36.5px'
							}}
							disabled={isLoading}
						>
							{isLoading ? (
								<CircularProgress color="inherit" size={24} />
							) : (
								t('to-save')
							)}
						</Button>
					)}
				</Stack>
			</Box>
		</Box>
	)
}
