const regex = {
	formatCNPJ: (value: string) => {
		return value.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			'$1.$2.$3/$4-$5'
		)
	},
	formatCPF: (value: string) => {
		return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
	},
	formatPercentage: (value: number, lang: string) => {
		const percentage = (value * 100).toFixed(2)
		if (lang.toLowerCase() === 'pt-br') {
			return percentage.replace(/\./g, ',') + '%'
		}
		return percentage.replace(/\./g, '.') + '%'
	},
	formatStringToNumber: (value: string) => {
		value = value.replace(/\D/g, '') // remove non-digits
		value = value.replace(/^0+/, '') // remove leading zeros
		return value
	},
	formatStringToSlug: (text: string) => {
		return text
			.toLowerCase() // Convert the string to lowercase letters
			.replace(/^\s+|\s+$/g, '') // Remove leading and trailing spaces
			.normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
			.replace(/[\u0300-\u036f]/g, '') // Remove all previously normalized characters e.g. accents, umlauts etc.
			.replace(/[^a-z0-9 -]/g, '') // Remove all characters that are not the given ones
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(/-+/g, '-') // Replace multiple - with single -
	},
	formatPhone: (value: string) => {
		return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
	},
	formatZipCode: (value: string) => {
		return value.replace(/(\d{5})(\d{3})/, '$1-$2')
	},
	formatBRLCurrency: (value: string) => {
		value = value.replace(/\D/g, '') // remove non-digits
		value = value.replace(/^(\d+)(\d{2})$/, '$1,$2') // add cents
		value = value.replace(/(?=(\d{3})+(\D))\B/g, '.') // add thousands separator
		return value
	},
	parseBRLCurrencyToFloat: (value: string = '0') => {
		if (value === '') {
			return 0
		}

		return parseFloat(value?.replace(/\./g, '').replace(',', '.'))
	},
	parseObjectToQueryString: (obj: {
		[key: string]:
			| string
			| number
			| undefined
			| null
			| {[key: string]: boolean | string}
	}) => {
		// Remove propriedades undefined
		Object.keys(obj).forEach(key => {
			if (obj[key] === undefined || obj[key] === null) {
				delete obj[key]
			}
		})

		return Object.keys(obj)
			.map(key => {
				const value = obj[key]
				if (value && typeof value === 'object' && !Array.isArray(value)) {
					const nestedQueryString = Object.keys(value)
						.filter(nestedKey => value[nestedKey])
						.map(nestedKey => nestedKey)
						.join(',')
					return `${key}=${nestedQueryString}`
				}
				return `${key}=${value}`
			})
			.join('&')
	},
	removeAllNonDigits: (value: string) => {
		return value.replace(/\D/g, '')
	}
}

export {regex}
