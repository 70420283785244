import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {changeSalePagePublishStatus} from '../api/salePage'
import {SimpleDialog} from './Dialog'
import {LoadingDialog} from './LoadingDialog'
import AlertDialog from './AlertDialog'
import SwitchControl from './SwitchControl'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {
	Button,
	DialogActions,
	DialogContent,
	IconButton,
	Tooltip
} from '@mui/material'
import {CgClose as CloseIcon} from 'react-icons/cg'
import {PUBLIC_SITE_URL} from '../config'
import {FaRegClipboard as ClipboardIcon} from 'react-icons/fa6'
import {HiOutlineExternalLink as ExternalLinkIcon} from 'react-icons/hi'
import useAuthStates from '../stores/useAuthStates'

interface Message {
	message: string
}

const PublishPopoverMessage = ({
	experienceStatus,
	salePageSectionsCount
}: {
	experienceStatus: string
	salePageSectionsCount: number
}) => {
	const {t} = useTranslation()

	const errorMessages = () => {
		let messages: string[] = []

		if (experienceStatus !== 'active') {
			messages.push(t('error-publish-sale-page-experience-not-active'))
		}
		if (!salePageSectionsCount) {
			messages.push(t('error-publish-sale-page-nothing-sections'))
		}

		return messages
	}

	if (errorMessages().length === 0) {
		return <Typography>{t('info-sale-page-publish-enabled')}</Typography>
	}

	return (
		<>
			<Typography>{t('info-sale-page-publish-disabled')}</Typography>
			<ol>
				{errorMessages().map((message, index) => (
					<li key={index}>
						<Typography>{message}</Typography>
					</li>
				))}
			</ol>
		</>
	)
}

interface SalePageSwitchPublishedProps {
	data: {
		salePage: {
			_id: string
			published: boolean
			sectionsCount: number
		}
		experience: {
			_id: string
			slug: string
			status: string
		}
	}
	onChangePublish?: () => void
	anchorOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number
		horizontal: 'left' | 'center' | 'right' | number
	}
	transformOrigin?: {
		vertical: 'top' | 'center' | 'bottom' | number
		horizontal: 'left' | 'center' | 'right' | number
	}
}

const SalePageSwitchPublished = ({
	data,
	onChangePublish,
	anchorOrigin,
	transformOrigin
}: SalePageSwitchPublishedProps) => {
	const {t} = useTranslation()
	const {profile, salePage} = useAuthStates()
	const [openAlert400, setOpenAlert400] = useState<boolean>(false)
	const [alert400Message, setAlert400Message] = useState<Message[]>()
	const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false)
	const [alertDialogMessage, setAlertDialogMessage] = useState<string>('')
	const [openLoadingDialog, setOpenLoadingDialog] = useState<boolean>(false)
	const [published, setPublished] = useState(data.salePage.published)
	const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false)
	const [copyLinkTooltip, setCopyLinkTooltip] = useState<string>(
		t('copy-sale-page-link')
	)

	const handleCopyLink = () => {
		navigator.clipboard
			.writeText(
				`${PUBLIC_SITE_URL}/${profile?.nameSlug}/${data.experience.slug}`
			)
			.then(() => {
				setCopyLinkTooltip(t('copied-link'))
			})
	}

	const {mutate: mutateChangePublishStatus} = useMutation(
		changeSalePagePublishStatus,
		{
			networkMode: 'always'
		}
	)

	const handleChangePublishStatus = () => {
		setOpenLoadingDialog(true)
		mutateChangePublishStatus(
			{
				experienceId: data.experience._id,
				salePageId: data.salePage._id,
				publish: !published
			},
			{
				onSuccess: result => {
					setOpenLoadingDialog(false)
					if (result.responseStatusCode === 200) {
						setPublished(prev => !prev)
						onChangePublish && onChangePublish()
						if (!published) {
							setOpenPublishDialog(true)
						}
					} else if (
						result.responseStatusCode === 400 &&
						result.error?.fields
					) {
						setAlert400Message(result.error.fields?.publish)
						setOpenAlert400(true)
					} else if (result.error?.message) {
						setAlertDialogMessage(result.error.message)
						setOpenAlertDialog(true)
					} else {
						setAlertDialogMessage(t('error-server-default'))
						setOpenAlertDialog(true)
					}
				},
				onError: error => {
					setOpenLoadingDialog(false)
					setAlertDialogMessage(t('error-server-default'))
					console.log(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}

	useEffect(() => {
		setPublished(data.salePage.published)
	}, [data])

	return (
		<>
			<Dialog
				open={openPublishDialog}
				onClose={() => setOpenPublishDialog(false)}
				sx={{
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: 450,
						borderRadius: 2
					}
				}}
			>
				<IconButton
					sx={theme => ({
						color: theme.palette.grey[500],
						padding: 1.5,
						position: 'absolute',
						right: 0,
						'&:hover': {
							cursor: 'pointer',
							color: theme.palette.grey[600]
						}
					})}
					size="small"
					onClick={() => setOpenPublishDialog(false)}
					aria-label={t('aria-close-modal')}
				>
					<CloseIcon fontSize="inherit" />
				</IconButton>
				<DialogTitle>
					<Typography variant="h3" textAlign="center">
						🎉
					</Typography>
					<Typography variant="h6" textAlign="center" mt={2}>
						{t('sale-page-published')}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography textAlign="center">
						{t('sale-page-published-info')}
					</Typography>
					<DialogActions
						sx={{
							mt: 2,
							alignSelf: 'center',
							justifyContent: 'center',
							flexWrap: 'wrap',
							gap: 2
						}}
					>
						<Button
							onClick={handleCopyLink}
							startIcon={<ClipboardIcon fontSize={17} />}
							variant="outlined"
						>
							<Tooltip
								title={copyLinkTooltip}
								placement="bottom"
								arrow
								onClose={() => setCopyLinkTooltip(t('copy-sale-page-link'))}
							>
								<Typography
									variant="body1"
									sx={{
										alignItems: 'center',
										cursor: 'pointer',
										display: 'flex',
										gap: 0.5,
										width: 'max-content'
									}}
								>
									<span>{t('copy-link')}</span>
								</Typography>
							</Tooltip>
						</Button>
						<Button
							variant="contained"
							startIcon={<ExternalLinkIcon fontSize={20} />}
							href={`${PUBLIC_SITE_URL}/${profile?.nameSlug}/${data.experience.slug}`}
							target="_blank"
						>
							{t('visit-page')}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<LoadingDialog
				open={openLoadingDialog}
				message={published ? t('unpublishing') : t('publishing')}
			/>
			<AlertDialog
				open={openAlertDialog}
				message={alertDialogMessage}
				onClose={() => setOpenAlertDialog(false)}
			/>
			<SimpleDialog open={openAlert400} onClose={() => setOpenAlert400(false)}>
				<Typography variant="subtitle1">
					{t('error-publish-sale-page-unmet-requirements')}
				</Typography>
				<ol>
					{alert400Message?.map((message, index) => (
						<li>
							<Typography variant="body1">{message.message}</Typography>
						</li>
					))}
				</ol>
			</SimpleDialog>
			<SwitchControl
				label={published ? t('published') : t('not-published')}
				tooltip={published ? t('to-unpublish') : undefined}
				checked={published}
				popover={
					!published && (
						<Box sx={{p: 2}}>
							<PublishPopoverMessage
								experienceStatus={data.experience.status}
								salePageSectionsCount={salePage?.sections.length || 0}
							/>
						</Box>
					)
				}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				disabled={
					salePage?.sections.length === 0 || data.experience.status !== 'active'
				}
				onChange={handleChangePublishStatus}
			/>
		</>
	)
}

export default SalePageSwitchPublished
