import {DEFAULT_LANG, PS_ASAAS_API} from '../../config'
import {authenticatedFetch} from '../../helpers/authenticatedFetch'
import {file as fileHelper} from '../../helpers/file'
import useAppStates from '../../stores/useAppStates'
import useAuthStates from '../../stores/useAuthStates'

interface GetAsaasSubAccountResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		companyType: string
		incomeValue: number
		generalStatus: string
		commercialStatus: string
		documentStatus: string
		onboardingStep: number
		onboardingCompleted: boolean
		pixAddressKey: string
		pixAddressKeyType: string
		documents?: {
			'identification-and-selfie'?: AsaasSubAccountDocument
			'social-contract'?: AsaasSubAccountDocument
			'entrepreneur-requirement'?: AsaasSubAccountDocument
			'minutes-of-election'?: AsaasSubAccountDocument
			custom?: AsaasSubAccountDocument
		}
	}
}

export const getAsaasSubAccount =
	async (): Promise<GetAsaasSubAccountResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(`${PS_ASAAS_API}/sub-account`, {
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		})

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface CreateAsaasSubAccount {
	error?: {
		message: string
		fields: {
			incomeValue: {message: string}[]
			companyType: {message: string}[]
		}
	}
	responseStatusCode?: number
	success?: Message
}

export const createAsaasSubAccount = async (values: {
	companyType: string
	incomeValue: number
}): Promise<CreateAsaasSubAccount> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(`${PS_ASAAS_API}/sub-account`, {
		method: 'POST',
		headers: {
			'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
			'Authorization': `Bearer ${accessToken}`
		},
		body: JSON.stringify(values)
	})

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

export type AsaasSubAccountDocument = {
	id: string
	type: string
	status: 'not-sent' | 'pending' | 'approved' | 'rejected' | 'awaiting-approval'
	title: string
	description: string
	url: string | null
}

interface GetAsaasSubAccountDocumentsURLResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		'identification-and-selfie'?: AsaasSubAccountDocument
		'social-contract'?: AsaasSubAccountDocument
		'entrepreneur-requirement'?: AsaasSubAccountDocument
		'minutes-of-election'?: AsaasSubAccountDocument
		custom?: AsaasSubAccountDocument
	}
}

export const getAsaasSubAccountDocuments =
	async (): Promise<GetAsaasSubAccountDocumentsURLResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${PS_ASAAS_API}/sub-account/documents`,
			{
				method: 'GET',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				}
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface SendAsaasSubAccountDocumentsResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		commercialStatus: 'pending' | 'awaiting-approval' | 'approved' | 'rejected'
		documentStatus:
			| 'pending'
			| 'awaiting-approval'
			| 'approved'
			| 'rejected'
			| 'not-sent'
		generalStatus: 'pending' | 'awaiting-approval' | 'approved' | 'rejected'
	}
}

export const completeAsaasOnboarding =
	async (): Promise<SendAsaasSubAccountDocumentsResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${PS_ASAAS_API}/sub-account/onboarding`,
			{
				method: 'PATCH',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				}
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}

interface SendAsaasSubAccountDocumentResponse {
	error?: {
		message: string
		fields: {
			documentFile: Message[]
		}
	}
	responseStatusCode?: number
	data?: {
		status: 'not-sent' | 'pending' | 'approved' | 'rejected'
	}
}

export const sendAsaasSubAccountDocument = async ({
	id,
	type,
	file
}: {
	id: string
	type: string
	file: File
}): Promise<SendAsaasSubAccountDocumentResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const fileBase64 = await fileHelper.blobToBase64(file)

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/documents`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				id,
				type,
				fileName: file.name,
				fileBase64
			})
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface MarkSubAccountDocumentStatusAsDoneResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		status: 'not-sent' | 'pending' | 'approved' | 'rejected'
	}
}

export const markSubAccountDocumentStatusAsDone = async ({
	id,
	type
}: {
	id: string
	type: string
}): Promise<MarkSubAccountDocumentStatusAsDoneResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/documents/${id}/mark-as-done`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({type})
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface GetBalanceAsaasResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		balance: number
		receivableBalance: number
	}
}

export const getBalanceAsaas = async (): Promise<GetBalanceAsaasResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/balance`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface CreateTransferAsaasResponse {
	error?: {
		message: string
		fields: {
			amount: Message[]
		}
	}
	responseStatusCode?: number
	data?: {
		id: string
		balance: number
		status: string
		feeAmount: number
		amount: number
		bankAccount: {
			accountType: 'checking' | 'savings'
			accountNumber: string
			accountCheckDigit: string
			bankCode: string
			branchNumber: string
		}
	}
}

export const createTransferAsaas = async (values: {
	amount: number
	operationType: string
}): Promise<CreateTransferAsaasResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/transfers`,
		{
			method: 'POST',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface UpdateBankAccountAsaasResponse {
	success?: {
		message: string
	}
	error?: {
		message: string
		fields?: {
			accountNumber?: Message[]
			accountCheckDigit?: Message[]
			accountType?: Message[]
			bankNumber?: Message[]
			branchNumber?: Message[]
			branchCheckDigit?: Message[]
		}
	}
	responseStatusCode?: number
}

export const updateBankAccountAsaas = async (values: {
	accountType: 'checking' | 'savings'
	accountNumber: string
	accountCheckDigit: string
	bankCode: string
	branchNumber: string
	branchCheckDigit?: string
}): Promise<UpdateBankAccountAsaasResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/bank-account`,
		{
			method: 'PUT',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(values)
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface FetchTransferAsaasResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		hasMore: boolean
		transfers: {
			id: string
			transferId: string
			amount: number
			operationType: string
			feeAmount?: number
			status: string
			createdAt: string
			bankAccount?: {
				accountType: 'checking' | 'savings'
				accountNumber: string
				accountCheckDigit: string
				bankCode: string
				branchNumber: string
			}
		}[]
	}
}

export const fetchTransferAsaas = async ({
	offset,
	limit
}: {
	offset: number
	limit: number
}): Promise<FetchTransferAsaasResponse> => {
	const currentLang = useAppStates.getState().currentLang
	const accessToken = useAuthStates.getState().accessToken

	const response = await authenticatedFetch(
		`${PS_ASAAS_API}/sub-account/transfers?offset=${offset}&limit=${limit}`,
		{
			method: 'GET',
			headers: {
				'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
				'Authorization': `Bearer ${accessToken}`
			}
		}
	)

	const data = await response.json()
	return {...data, responseStatusCode: response.status}
}

interface GetAnticipationAsaasResponse {
	error?: Message
	responseStatusCode?: number
	data?: {
		total: number
		available: number
	}
}

export const getAnticipationConfigAsaas =
	async (): Promise<GetAnticipationAsaasResponse> => {
		const currentLang = useAppStates.getState().currentLang
		const accessToken = useAuthStates.getState().accessToken

		const response = await authenticatedFetch(
			`${PS_ASAAS_API}/sub-account/anticipations/config`,
			{
				method: 'GET',
				headers: {
					'Accept-Language': currentLang ? currentLang : DEFAULT_LANG,
					'Authorization': `Bearer ${accessToken}`
				}
			}
		)

		const data = await response.json()
		return {...data, responseStatusCode: response.status}
	}
