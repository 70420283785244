import {PS_FEES} from '../config'

type PaymentService = 'asaas' | 'pagarme'
type Installment = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const truncateDecimal = (value: number) => {
	return Math.floor(value * 100) / 100
}

const roundDecimal = (value: number) => {
	return parseFloat(value.toFixed(2))
}

const generateInstallments = (amount: number, numInstallments: number) => {
	if (numInstallments === 1) {
		return [amount]
	}

	const initialInstallments = truncateDecimal(amount / numInstallments)
	const countInitialInstallments = truncateDecimal(
		initialInstallments * (numInstallments - 1)
	)
	const lastInstallment = truncateDecimal(amount - countInitialInstallments)

	return Array.from({length: numInstallments}).map((_, i) => {
		if (i === numInstallments - 1) {
			return lastInstallment
		} else {
			return initialInstallments
		}
	})
}

const generateInstallmentsWithCalcMdrAndProcessingFees = (
	amount: number,
	numInstallments: Installment,
	paymentService: PaymentService,
	passCreditCardFees: boolean
) => {
	const fees = PS_FEES[paymentService]
	const percentageFee = fees.mdr[numInstallments]
	const totalFixedFee = fees.fixedFee

	if (!passCreditCardFees) {
		const installments = generateInstallments(amount, numInstallments)
		const installmentFixedFee =
			numInstallments === 1
				? totalFixedFee
				: truncateDecimal(totalFixedFee / numInstallments)

		const calcDetails = installments.map((grossAmount, i) => {
			const totalFeesAmount = grossAmount * percentageFee + installmentFixedFee
			const netAmount = grossAmount - totalFeesAmount

			return {
				installmentNumber: i + 1,
				grossAmount,
				netAmount: truncateDecimal(netAmount),
				fees: {
					processing: totalFeesAmount
				},
				calcDetailsProcessingFees: {
					baseAmount: grossAmount,
					netAmount,
					mdrFeePercentage: percentageFee,
					fixedFeeAmount: installmentFixedFee,
					totalFeesAmount: truncateDecimal(totalFeesAmount)
				}
			}
		})

		const totalNetAmount = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.netAmount, 0)
		)
		const totalGrossAmount = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.grossAmount, 0)
		)
		const totalFees = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.fees.processing, 0)
		)

		return {
			paymentService,
			numInstallments,
			installmentAmount: calcDetails[0].grossAmount,
			totalNetAmount,
			totalGrossAmount,
			totalFees: {
				processingPercentage: percentageFee,
				processingFixed: totalFixedFee,
				processingTotalAmount: totalFees
			},
			installmentsDetails: calcDetails
		}
	} else {
		const grossAmount = truncateDecimal(
			(amount + totalFixedFee) / (1 - percentageFee)
		)
		const installments = generateInstallments(grossAmount, numInstallments)
		const installmentFixedFee =
			numInstallments === 1
				? totalFixedFee
				: truncateDecimal(totalFixedFee / numInstallments)

		const calcDetails = installments.map((grossAmount, i) => {
			const totalFeesAmount = truncateDecimal(
				grossAmount * percentageFee + installmentFixedFee
			)
			const netAmount = grossAmount - totalFeesAmount

			return {
				installmentNumber: i + 1,
				grossAmount,
				netAmount: truncateDecimal(netAmount),
				fees: {
					processing: totalFeesAmount
				},
				calcDetailsProcessingFees: {
					baseAmount: grossAmount,
					netAmount,
					mdrFeePercentage: percentageFee,
					fixedFeeAmount: installmentFixedFee,
					totalFeesAmount
				}
			}
		})

		const totalNetAmount = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.netAmount, 0)
		)
		const totalGrossAmount = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.grossAmount, 0)
		)
		const totalFees = truncateDecimal(
			calcDetails.reduce((acc, item) => acc + item.fees.processing, 0)
		)

		return {
			paymentService,
			numInstallments,
			installmentAmount: calcDetails[0].grossAmount,
			totalNetAmount,
			totalGrossAmount,
			totalFees: {
				processingPercentage: percentageFee,
				processingFixed: totalFixedFee,
				processingTotalAmount: totalFees
			},
			installmentsDetails: calcDetails
		}
	}
}

const calculateAnticipationFee = (
	baseAmount: number,
	installmentNumber: number,
	paymentService: PaymentService
) => {
	const anticipationDailyFee = PS_FEES[paymentService].anticipationDailyFee
	const anticipationInterval = PS_FEES[paymentService].daysToAnticipation
	const anticipationDays = installmentNumber * anticipationInterval
	const anticipationFee = baseAmount * anticipationDays * anticipationDailyFee

	return roundDecimal(anticipationFee)
}

const generateCalcDetailsWithAnticipation = (
	amount: number,
	numInstallments: Installment,
	paymentService: PaymentService,
	passCreditCardFees: boolean
) => {
	const dailyInterestRate = PS_FEES[paymentService].anticipationDailyFee

	let calcDetails = generateInstallmentsWithCalcMdrAndProcessingFees(
		amount,
		numInstallments,
		paymentService,
		passCreditCardFees
	)

	const installmentsDetails = calcDetails.installmentsDetails.map(
		installment => {
			if (installment.installmentNumber === 1) {
				return {
					...installment,
					fees: {
						...installment.fees,
						anticipation: 0
					}
				}
			}

			const anticipationFee = calculateAnticipationFee(
				installment.netAmount,
				installment.installmentNumber - 1,
				paymentService
			)

			const netAmount = installment.netAmount - anticipationFee
			const anticipationInterval = PS_FEES[paymentService].daysToAnticipation
			const anticipationDays =
				(installment.installmentNumber - 1) * anticipationInterval

			return {
				...installment,
				netAmount,
				fees: {
					...installment.fees,
					anticipation: anticipationFee
				},
				calcDetailsAnticipationFees: {
					baseAmount: truncateDecimal(installment.netAmount),
					netAmount: truncateDecimal(netAmount),
					anticipationDailyFeePercentage: dailyInterestRate,
					daysToAnticipation: anticipationDays,
					totalFeesAmount: anticipationFee
				}
			}
		}
	)

	const totalNetAmount = truncateDecimal(
		installmentsDetails.reduce((acc, item) => acc + item.netAmount, 0)
	)

	const totalAnticipationFees = truncateDecimal(
		installmentsDetails.reduce((acc, item) => acc + item.fees.anticipation, 0)
	)

	return {
		...calcDetails,
		totalNetAmount,
		totalFees: {
			...calcDetails.totalFees,
			anticipationPercentage: dailyInterestRate,
			anticipationTotalAmount: totalAnticipationFees
		},
		installmentsDetails
	}
}

const generateInstallmentsWithCalcMdrAndProcessingFeesAndAnticipationFees = (
	amount: number,
	numInstallments: Installment,
	paymentService: PaymentService,
	passCreditCardFees: boolean,
	passAnticipationFees: boolean
): {
	paymentService: 'asaas' | 'pagarme'
	numInstallments: number
	installmentAmount: number
	totalNetAmount: number
	totalGrossAmount: number
	totalFees: {
		processingPercentage: number
		processingFixed: number
		processingTotalAmount: number
		anticipationPercentage?: number
		anticipationTotalAmount?: number
	}
	installmentsDetails: {
		installmentNumber: number
		grossAmount: number
		netAmount: number
		fees: {
			processing: number
			anticipation?: number
		}
	}[]
} => {
	// Antecipação Ativada

	const dailyInterestRate = PS_FEES[paymentService].anticipationDailyFee
	const anticipationInterval = PS_FEES[paymentService].daysToAnticipation
	const totalFixedFee = PS_FEES[paymentService].fixedFee
	const mdrPercentageFee = PS_FEES[paymentService].mdr[numInstallments]

	if (!passCreditCardFees && !passAnticipationFees) {
		// Não Repasse Taxas Cartão e Não Repasse Taxas Antecipação

		return generateCalcDetailsWithAnticipation(
			amount,
			numInstallments,
			paymentService,
			passCreditCardFees
		)
	} else if (passCreditCardFees && !passAnticipationFees) {
		// Repasse Taxas Cartão e Não Repasse Taxas Antecipação

		return generateCalcDetailsWithAnticipation(
			amount,
			numInstallments,
			paymentService,
			passCreditCardFees
		)
	} else if (passCreditCardFees && passAnticipationFees) {
		//Repasse Taxas Cartão e Repasse Taxas Antecipação

		const calculateReceivedAmount = (grossAmount: number) => {
			let remainingAmount = grossAmount - totalFixedFee // Subtrair a taxa fixa
			remainingAmount *= 1 - mdrPercentageFee // Subtrair a taxa percentual

			const installmentAmount = remainingAmount / numInstallments // Calcular o valor de cada parcela antes da antecipação
			let totalInstallmentsAmount = installmentAmount // Primeira parcela não tem antecipação

			for (let i = 1; i < numInstallments; i++) {
				const anticipationDays = anticipationInterval * i
				const installmentAnticipatedAmount =
					installmentAmount * (1 - dailyInterestRate * anticipationDays)
				totalInstallmentsAmount += installmentAnticipatedAmount
			}

			return totalInstallmentsAmount
		}

		const expectedNetAmount = amount

		const sumDaysToAnticipate = Array.from({length: numInstallments})
			.map((_, i) => i * anticipationInterval) // Calcular o número de dias para cada parcela
			.reduce((acc, dias) => acc + dias, 0) // Somar todos os dias

		let grossAmount = expectedNetAmount
		let netAmountReceived = 0

		do {
			grossAmount =
				(expectedNetAmount + totalFixedFee) /
				(1 -
					mdrPercentageFee -
					(sumDaysToAnticipate * dailyInterestRate) / numInstallments)

			netAmountReceived = calculateReceivedAmount(grossAmount)

			grossAmount += 0.01 // Incrementa o valor bruto para ajustar
		} while (netAmountReceived < expectedNetAmount)

		return generateCalcDetailsWithAnticipation(
			grossAmount,
			numInstallments,
			paymentService,
			false
		)
	} else {
		//  Não Repasse Taxas Cartão e Repasse Taxas Antecipação
		const calculateReceivedAmount = (grossAmount: number) => {
			let remainingAmount = grossAmount - totalFixedFee // Subtrair a taxa fixa
			remainingAmount *= 1 - mdrPercentageFee // Subtrair a taxa percentual

			const installmentAmount = remainingAmount / numInstallments // Calcular o valor de cada parcela antes da antecipação
			let totalInstallmentsAmount = installmentAmount // Primeira parcela não tem antecipação

			for (let i = 1; i < numInstallments; i++) {
				const anticipationDays = anticipationInterval * i
				const installmentAnticipatedAmount =
					installmentAmount * (1 - dailyInterestRate * anticipationDays)
				totalInstallmentsAmount += installmentAnticipatedAmount
			}

			return totalInstallmentsAmount
		}

		const expectedNetAmount = amount - amount * mdrPercentageFee + totalFixedFee

		let grossAmountMin = expectedNetAmount
		let grossAmountMax = expectedNetAmount * 1.5
		let grossAmount = (grossAmountMin + grossAmountMax) / 2

		const precision = 0.01

		while (grossAmountMax - grossAmountMin > precision) {
			const amountReceived = calculateReceivedAmount(grossAmount)

			if (amountReceived < expectedNetAmount) {
				grossAmountMin = grossAmount
			} else {
				grossAmountMax = grossAmount
			}

			grossAmount = (grossAmountMin + grossAmountMax) / 2
		}

		return generateCalcDetailsWithAnticipation(
			grossAmount,
			numInstallments,
			paymentService,
			false
		)
	}
}

const discountExcofyFee = (calcDetails: {
	paymentService: 'asaas' | 'pagarme'
	numInstallments: number
	installmentAmount: number
	totalNetAmount: number
	totalGrossAmount: number
	totalFees: {
		processingPercentage: number
		processingFixed: number
		processingTotalAmount: number
		anticipationPercentage?: number
		anticipationTotalAmount?: number
	}
	installmentsDetails: {
		installmentNumber: number
		grossAmount: number
		netAmount: number
		fees: {
			processing: number
			anticipation?: number
		}
	}[]
}) => {
	const excofyPercentageFee = 0.03
	const excofyTotalAmount = calcDetails.totalGrossAmount * excofyPercentageFee
	const totalNetAmount = calcDetails.totalNetAmount - excofyTotalAmount

	return {
		...calcDetails,
		totalNetAmount,
		totalFees: {
			...calcDetails.totalFees,
			excofyPercentage: excofyPercentageFee,
			excofyTotalAmount
		}
	}
}

export const simulateSale = (
	amount: number,
	numInstallments: Installment,
	paymentService: PaymentService,
	passCreditCardFees: boolean,
	enableAnticipation: boolean,
	passAnticipationFees: boolean
): {
	paymentService: PaymentService
	numInstallments: number
	installmentAmount: number
	totalNetAmount: number
	totalGrossAmount: number
	totalFees: {
		processingPercentage: number
		processingFixed: number
		processingTotalAmount: number
		anticipationPercentage?: number
		anticipationTotalAmount?: number
		excofyPercentage?: number
		excofyTotalAmount?: number
	}
	installmentsDetails: {
		installmentNumber: number
		grossAmount: number
		netAmount: number
		fees: {
			processing: number
			anticipation?: number
		}
	}[]
} => {
	if ((!enableAnticipation && !passCreditCardFees) || numInstallments === 1) {
		// 1º Caso: Antecipação Desativada e Não Repasse Taxas Cartão
		const result = generateInstallmentsWithCalcMdrAndProcessingFees(
			amount,
			numInstallments,
			paymentService,
			numInstallments === 1 ? false : passCreditCardFees
		)

		return discountExcofyFee(result)
	} else if (!enableAnticipation && passCreditCardFees) {
		// 2º Caso: Antecipação Desativada e Repasse Taxas Cartão
		const result = generateInstallmentsWithCalcMdrAndProcessingFees(
			amount,
			numInstallments,
			paymentService,
			passCreditCardFees
		)

		return discountExcofyFee(result)
	} else if (
		enableAnticipation &&
		!passCreditCardFees &&
		!passAnticipationFees
	) {
		// 3º Caso: Antecipação Ativada e Não Repasse Taxas Cartão e Não Repasse Taxas Antecipação
		const result =
			generateInstallmentsWithCalcMdrAndProcessingFeesAndAnticipationFees(
				amount,
				numInstallments,
				paymentService,
				passCreditCardFees,
				passAnticipationFees
			)

		return discountExcofyFee(result)
	} else if (
		enableAnticipation &&
		passCreditCardFees &&
		!passAnticipationFees
	) {
		// 4º Caso: Antecipação Ativada e Repasse Taxas Cartão e Não Repasse Taxas Antecipação
		const result =
			generateInstallmentsWithCalcMdrAndProcessingFeesAndAnticipationFees(
				amount,
				numInstallments,
				paymentService,
				passCreditCardFees,
				passAnticipationFees
			)

		return discountExcofyFee(result)
	} else if (enableAnticipation && passCreditCardFees && passAnticipationFees) {
		// 5º Caso: Antecipação Ativada e Repasse Taxas Cartão e Repasse Taxas Antecipação
		const result =
			generateInstallmentsWithCalcMdrAndProcessingFeesAndAnticipationFees(
				amount,
				numInstallments,
				paymentService,
				passCreditCardFees,
				passAnticipationFees
			)

		return discountExcofyFee(result)
	} else {
		// 6º Caso: Antecipação Ativada e Não Repasse Taxas Cartão e Repasse Taxas Antecipação
		const result =
			generateInstallmentsWithCalcMdrAndProcessingFeesAndAnticipationFees(
				amount,
				numInstallments,
				paymentService,
				passCreditCardFees,
				passAnticipationFees
			)

		return discountExcofyFee(result)
	}
}
