import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {price} from '../helpers/price'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {regex} from '../helpers/regex'
import {AsaasLogo} from './logos/AsaasLogo'
import {PagarmeLogo} from './logos/PagarmeLogo'
import {Status} from './Status'
import useAuthStates from '../stores/useAuthStates'
import {EXCOFY_FEE, PS_FEES} from '../config'
import useAppStates from '../stores/useAppStates'
import {useCallback, useState} from 'react'
import {Dialog} from './Dialog'
import {simulateSale} from '../helpers/simulateSale'
import InfoPopover from './InfoPopover'

type Installment = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const useValidationSchema = () => {
	const {t} = useTranslation()

	const validationSchema = yup.object({
		paymentService: yup
			.string()
			.oneOf(['asaas', 'pagarme'], t('error-payment-service-required'))
			.required(t('error-payment-service-required')),
		paymentMethod: yup
			.string()
			.oneOf(
				['credit-card', 'boleto', 'pix'],
				t('error-payment-method-required')
			)
			.required(t('error-payment-method-required')),
		amount: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-amount-required')),
		installments: yup
			.string()
			.matches(/^\d{1,2}$/, t('error-installments-invalid'))
	})

	return validationSchema
}

export const SaleSimulate = () => {
	const {t} = useTranslation()
	const {paymentService} = useAuthStates()
	const {currentLang} = useAppStates()
	const validationSchema = useValidationSchema()

	const [openSimulationResult, setOpenSimulationResult] = useState(false)
	const [simulationResult, setSimulationResult] = useState<{
		paymentService: 'asaas' | 'pagarme'
		numInstallments: number
		installmentAmount: number
		totalNetAmount: number
		totalGrossAmount: number
		totalFees: {
			processingPercentage: number
			processingFixed: number
			processingTotalAmount: number
			anticipationPercentage?: number
			anticipationTotalAmount?: number
			excofyPercentage?: number
			excofyTotalAmount?: number
		}
		installmentsDetails: {
			installmentNumber: number
			grossAmount: number
			netAmount: number
			fees: {
				processing: number
				anticipation?: number
			}
		}[]
	} | null>(null)

	const formik = useFormik({
		initialValues: {
			paymentService:
				paymentService?.activeService || ('asaas' as 'asaas' | 'pagarme'),
			paymentMethod: 'credit-card',
			amount: price.decimal(100),
			installments: 12 as Installment,
			anticipationEnabled: true,
			passAnticipationFeesToCustomer: true,
			passCreditCardFeesToCustomer: true
		},
		validationSchema,
		onSubmit: values => {}
	})

	const getInstallmentValue = useCallback(
		(numInstallments: Installment) => {
			return price.full(
				simulateSale(
					regex.parseBRLCurrencyToFloat(formik.values.amount),
					numInstallments,
					formik.values.paymentService,
					formik.values.passCreditCardFeesToCustomer,
					formik.values.anticipationEnabled,
					formik.values.passAnticipationFeesToCustomer
				).installmentAmount
			)
		},
		[formik]
	)

	const handleSimulateSale = useCallback(() => {
		const result = simulateSale(
			regex.parseBRLCurrencyToFloat(formik.values.amount),
			formik.values.installments,
			formik.values.paymentService,
			formik.values.passCreditCardFeesToCustomer,
			formik.values.anticipationEnabled,
			formik.values.passAnticipationFeesToCustomer
		)

		setSimulationResult(result)
		setOpenSimulationResult(true)
	}, [formik])

	return (
		<>
			{openSimulationResult && simulationResult && (
				<Dialog.Root open={openSimulationResult}>
					<Dialog.Header>
						<Dialog.Title onClose={() => setOpenSimulationResult(false)}>
							{t('simulation-result')}
						</Dialog.Title>
					</Dialog.Header>
					<Dialog.Body sx={{pt: '16px !important'}}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								rowGap: 1
							}}
						>
							<Box>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 0.5,
										alignItems: 'center',
										justifyContent: 'space-between',
										borderBottom: '1px solid',
										borderBottomColor: 'divider',
										pb: 1
									}}
								>
									<Typography variant="body1">{t('gross-amount')}</Typography>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
										<Typography variant="body2" color="text.secondary">
											{'('}
											{formik.values.installments}
											{'x '}
											{t('of')} {price.full(simulationResult.installmentAmount)}
											{')'}
										</Typography>
										<Typography variant="body1" fontWeight={500}>
											{price.full(simulationResult.totalGrossAmount)}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 0.5,
										alignItems: 'center',
										justifyContent: 'space-between',
										py: 1
									}}
								>
									<Typography variant="body1">{t('net-amount')}</Typography>
									<Typography variant="body1" fontWeight={500}>
										{price.full(simulationResult.totalNetAmount)}
									</Typography>
								</Box>
							</Box>
							<Box>
								<Typography component="h2" variant="subtitle1" mb={1} mt={1}>
									{t('fees-details')}
								</Typography>
								<Typography variant="body2">
									{formik.values.paymentMethod === 'credit-card' &&
									!simulationResult.totalFees.anticipationTotalAmount
										? t('sale-simulation-result-describe-credit-card-01', {
												processingFee:
													regex.formatPercentage(
														simulationResult.totalFees.processingPercentage,
														currentLang
													) +
													' + ' +
													price.full(
														simulationResult.totalFees.processingFixed
													),
												excofyFee: regex.formatPercentage(
													EXCOFY_FEE,
													currentLang
												)
										  })
										: null}
									{formik.values.paymentMethod === 'credit-card' &&
									simulationResult.totalFees.anticipationPercentage
										? t('sale-simulation-result-describe-credit-card-02', {
												processingFee:
													regex.formatPercentage(
														simulationResult.totalFees.processingPercentage,
														currentLang
													) +
													' + ' +
													price.full(
														simulationResult.totalFees.processingFixed
													),
												anticipationFee: regex.formatPercentage(
													PS_FEES[formik.values.paymentService]
														.anticipationMonthlyFee,
													currentLang
												),
												excofyFee: regex.formatPercentage(
													EXCOFY_FEE,
													currentLang
												)
										  })
										: null}
								</Typography>
							</Box>
						</Box>
					</Dialog.Body>
				</Dialog.Root>
			)}
			<Box>
				<Stack spacing={4}>
					<FormControl
						size="small"
						sx={{
							minWidth: 232
						}}
					>
						<InputLabel id="select-payment-service-label">
							{t('payment-service')}
						</InputLabel>
						<Select
							labelId="select-payment-service-label"
							id="select-payment-service"
							name="paymentService"
							variant="outlined"
							error={
								formik.touched.paymentService &&
								Boolean(formik.errors.paymentService)
							}
							value={formik.values.paymentService}
							label={t('payment-service')}
							onChange={e => {
								formik.handleChange(e)

								if (e.target.value === 'asaas') {
									formik.setFieldValue('anticipationEnabled', true)
									formik.setFieldValue('passAnticipationFeesToCustomer', true)
								} else {
									formik.setFieldValue('anticipationEnabled', false)
									formik.setFieldValue('passAnticipationFeesToCustomer', false)
								}
							}}
						>
							<MenuItem value="asaas">
								<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
									<AsaasLogo height={24} />
									{paymentService?.activeService === 'asaas' && (
										<Status.Tag type="active">
											<Status.Label
												label={t('active')}
												maxWidth="auto"
												showTooltip={false}
											/>
										</Status.Tag>
									)}
								</Box>
							</MenuItem>
							<MenuItem value="pagarme">
								<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
									<PagarmeLogo height={24} />
									{paymentService?.activeService === 'pagarme' && (
										<Status.Tag type="active">
											<Status.Label
												label={t('active')}
												maxWidth="auto"
												showTooltip={false}
											/>
										</Status.Tag>
									)}
								</Box>
							</MenuItem>
						</Select>
					</FormControl>
					<Box>
						<FormControl
							size="small"
							sx={{
								width: '100%'
							}}
						>
							<InputLabel id="select-payment-method-label">
								{t('payment-method')}
							</InputLabel>
							<Select
								labelId="select-payment-method-label"
								id="select-payment-method"
								name="paymentMethod"
								variant="outlined"
								error={
									formik.touched.paymentMethod &&
									Boolean(formik.errors.paymentMethod)
								}
								value={formik.values.paymentMethod}
								label={t('payment-method')}
								onChange={formik.handleChange}
								disabled
							>
								<MenuItem value="credit-card">{t('credit-card')}</MenuItem>
								<MenuItem value="pix">{t('pix')}</MenuItem>
								<MenuItem value="boleto">{t('boleto')}</MenuItem>
							</Select>
						</FormControl>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								flexDirection: 'column',
								gap: 1.5,
								mt: 1.5
							}}
						>
							{formik.values.paymentService === 'asaas' && (
								<>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														color: 'grey.500',
														py: 0
													}}
													name="anticipationEnabled"
													checked={formik.values.anticipationEnabled}
													onChange={e => {
														formik.handleChange(e)
														formik.setFieldValue(
															'passAnticipationFeesToCustomer',
															e.target.checked
														)
													}}
												/>
											}
											label={
												<Typography sx={{color: 'text.secondary'}}>
													{t('to-activate-anticipation')}
												</Typography>
											}
											sx={{
												'&.MuiFormControlLabel-root': {
													mr: 0
												}
											}}
										/>
										<InfoPopover>
											{t('automatic-anticipation-describe-2')}
										</InfoPopover>
									</Box>
									{formik.values.anticipationEnabled && (
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														color: 'grey.500',
														py: 0
													}}
													name="passAnticipationFeesToCustomer"
													checked={formik.values.passAnticipationFeesToCustomer}
													onChange={formik.handleChange}
												/>
											}
											label={
												<Typography sx={{color: 'text.secondary'}}>
													{t(
														'label-pass-credit-card-anticipation-fees-to-customer'
													)}
												</Typography>
											}
										/>
									)}
								</>
							)}
							<FormControlLabel
								control={
									<Checkbox
										sx={{
											color: 'grey.500',
											py: 0
										}}
										name="passCreditCardFeesToCustomer"
										checked={formik.values.passCreditCardFeesToCustomer}
										onChange={formik.handleChange}
									/>
								}
								label={
									<Typography sx={{color: 'text.secondary'}}>
										{t('label-pass-credit-card-fees-to-customer')}
									</Typography>
								}
							/>
						</Box>
					</Box>
					<TextField
						name="amount"
						label={t('sale-amount')}
						value={formik.values.amount}
						onChange={event => {
							event.target.value = regex.formatBRLCurrency(event.target.value)
							formik.handleChange(event)
						}}
						onBlur={formik.handleBlur}
						helperText={formik.touched.amount && formik.errors.amount}
						error={formik.touched.amount && Boolean(formik.errors.amount)}
						variant="outlined"
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">{'R$'}</InputAdornment>
							)
						}}
					/>
					<FormControl size="small">
						<InputLabel id="select-installments-label">
							{t('installments-quantity')}
						</InputLabel>
						<Select
							labelId="select-installments-label"
							id="select-installments"
							name="installments"
							variant="outlined"
							value={formik.values.installments}
							label={t('installments-quantity')}
							onChange={formik.handleChange}
							renderValue={installment => (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '4px',
										width: '100%'
									}}
								>
									<Typography>
										{installment}
										{'x '}
										{t('of')}{' '}
									</Typography>
									<Typography>{getInstallmentValue(installment)}</Typography>
								</Box>
							)}
						>
							{Array.from({length: 12}).map((_, i) => (
								<MenuItem
									key={'installment-' + i + 1}
									value={i + 1}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<Typography>{i + 1}x</Typography>
									<Typography color="text.secondary">
										{getInstallmentValue((i + 1) as Installment)}
									</Typography>
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							{t('helper-text-credit-card-fees', {
								percentageFee: regex.formatPercentage(
									PS_FEES[formik.values.paymentService].mdr[
										formik.values.installments
									],
									currentLang
								),
								fixedFee: price.full(
									PS_FEES[formik.values.paymentService].fixedFee
								)
							})}
						</FormHelperText>
					</FormControl>
					<Button
						size="large"
						variant="contained"
						type="submit"
						sx={{height: 40}}
						onClick={handleSimulateSale}
					>
						{t('to-simulate')}
					</Button>
				</Stack>
			</Box>
		</>
	)
}
