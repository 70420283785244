import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {regex} from '../../../helpers/regex'
import useAppStates from '../../../stores/useAppStates'
import {useState} from 'react'
import {price} from '../../../helpers/price'
import {useQuery} from '@tanstack/react-query'
import {getAnticipationConfigAsaas} from '../../../api/payment-service/asaas'
import parseToHTML from 'html-react-parser'
import Skeleton from '@mui/material/Skeleton'
import AlertDialog from '../../../components/AlertDialog'
import {PS_FEES_ASAAS} from '../../../config'

export const AsaasAnticipation = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [openAlertError, setOpenAlertError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [limits, setLimits] = useState({
		total: 0,
		available: 0
	})

	const {isLoading} = useQuery(
		['asaas-anticipation-config'],
		getAnticipationConfigAsaas,
		{
			refetchOnWindowFocus: false,
			onSuccess: ({data, error, responseStatusCode}) => {
				if (responseStatusCode === 200 && data) {
					setLimits({
						total: data.total,
						available: data.available
					})
				} else {
					setErrorMessage(error?.message || t('error-server-default'))
					setOpenAlertError(true)
				}
			},
			onError: error => {
				console.error(error)
				setErrorMessage(t('error-server-default'))
				setOpenAlertError(true)
			}
		}
	)

	return (
		<>
			<AlertDialog
				severity="error"
				open={openAlertError}
				message={errorMessage}
				onClose={() => setOpenAlertError(false)}
			/>
			<Box>
				<Box>
					<Typography variant="h5" component="h2">
						{t('automatic-anticipation')}
					</Typography>
				</Box>
				<Typography sx={{mt: 1}}>
					{t('automatic-anticipation-describe-2')}
				</Typography>
				<Box sx={styles.detailsItemBox}>
					<Box>
						<Typography variant="subtitle1" sx={styles.detailsSubtitle}>
							{t('anticipation-limits')}
						</Typography>
						<Typography
							variant="body2"
							sx={[
								styles.detailsText,
								{display: 'flex', alignItems: 'center', gap: 0.5}
							]}
						>
							{t('total')}:{' '}
							{isLoading ? (
								<Skeleton
									variant="rounded"
									width={100}
									height={12}
									sx={{display: 'inline-block'}}
								/>
							) : (
								<b>{price.full(limits.total)}</b>
							)}
						</Typography>
						<Typography
							variant="body2"
							sx={[
								styles.detailsText,
								{display: 'flex', alignItems: 'center', gap: 0.5}
							]}
						>
							{t('available')}:{' '}
							{isLoading ? (
								<Skeleton
									variant="rounded"
									width={100}
									height={12}
									sx={{display: 'inline-block'}}
								/>
							) : (
								<b>{price.full(limits.available)}</b>
							)}
						</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle1" sx={styles.detailsSubtitle}>
							{t('anticipation-fee')}
						</Typography>
						<Typography variant="body2" sx={styles.detailsText}>
							{parseToHTML(
								t('automatic-anticipation-fee', {
									fee: regex.formatPercentage(
										PS_FEES_ASAAS.anticipationMonthlyFee,
										currentLang
									)
								})
							)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

const styles = {
	detailsItemBox: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		columnGap: 7.5,
		rowGap: 2,
		mt: 3
	},
	detailsSubtitle: {
		mb: 0.5
	},
	detailsText: {
		color: 'grey.600'
	}
}
