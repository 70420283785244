import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useFormik} from 'formik'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputAdornment,
	Tooltip,
	Radio,
	RadioGroup,
	Skeleton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {MdOutlineCheck as CheckIcon} from 'react-icons/md'
import {FaCalculator as CalculatorIcon} from 'react-icons/fa'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {CgClose as ClearIcon} from 'react-icons/cg'

import {INPUT} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import useAppStates from '../../../stores/useAppStates'
import useEditionSchema from '../../../schemas/useEditionSchema'
import {Edition} from '../../../entities/Edition'
import {checkEditionSlug, updateEdition} from '../../../api/editions'
import {regex} from '../../../helpers/regex'
import SectionTitle from '../../typography/SectionTitle'
import AlertMessage from '../../AlertMessage'
import Tag from '../../typography/Tag'
import InfoPopover from '../../InfoPopover'
import {Dialog} from '../../Dialog'
import FormFeesCalculator from '../../FormFeesCalculator'
import CreditCardFees from '../../PagarmeFeesCalculator/CreditCardFees'
import BoletoFees from '../../PagarmeFeesCalculator/BoletoFees'
import PixFees from '../../PagarmeFeesCalculator/PixFees'
import {
	BoletoFeesCalculatorData,
	CreditCardFeesCalculatorData,
	PixFeesCalculatorData
} from '../../../api/feesCalculator'
import {OrderBumpCard} from './integrations/OrderBump'
import {SaleSimulate} from '../../SaleSimulate'
import ButtonIcon from '../../ButtonIcon'

const convertLocaleToDayjs = (locale: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		return 'pt-br'
	}

	return 'en'
}

interface FormUpdateEditionProps {
	edition?: Edition
}

const FormUpdateEdition = ({edition}: FormUpdateEditionProps) => {
	const {t} = useTranslation()

	const currentLanguage = useAppStates(state => state.currentLang)
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [lastSlugChecked, setLastSlugChecked] = useState<string>('')
	const [slugAvailable, setSlugAvailable] = useState<boolean>(false)
	const [slugHelper, setSlugHelper] = useState<string>(t('helper-slug'))
	const [openFeesCalculator, setOpenFeesCalculator] = useState<boolean>(false)
	const [openFeesDetails, setOpenFeesDetails] = useState<boolean>(false)
	const [openSimulateSale, setOpenSimulateSale] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState<
		'credit-card' | 'boleto' | 'pix'
	>('credit-card')
	const [creditCardFees, setCreditCardFees] =
		useState<CreditCardFeesCalculatorData>()
	const [boletoFees, setBoletoFees] = useState<BoletoFeesCalculatorData>()
	const [pixFees, setPixFees] = useState<PixFeesCalculatorData>()
	const [checkoutType, setCheckoutType] = useState<
		'one-page' | 'multiple-page'
	>('one-page')

	const [openDatePickerLimitDate, setOpenDatePickerLimitDate] = useState(false)
	const [openDatePickerStartDate, setOpenDatePickerStartDate] = useState(false)

	const sellerId = useAuthStates(state => state.sellerId)
	const editionSchema = useEditionSchema()
	const queryClient = useQueryClient()
	const {isLoading, mutate} = useMutation(updateEdition, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			title: edition?.title || '',
			slug: edition?.slug || '',
			description: edition?.description || '',
			price:
				regex.formatBRLCurrency(
					Number.parseFloat(String(edition?.price)).toFixed(2) // add 2 decimal places to price from database
				) || '',
			pricingUnit: edition?.pricingUnit || null,
			vacancies: edition?.vacancies || undefined,
			startDate: edition?.startDate ? dayjs(new Date(edition.startDate)) : null,
			limitDateToSell: edition?.limitDateToSell
				? dayjs(new Date(edition.limitDateToSell))
				: null,
			anticipationEnabled: edition?.anticipationEnabled || false,
			passAnticipationFeesToCustomer:
				edition?.passAnticipationFeesToCustomer || false,
			passCreditCardFeesToCustomer:
				edition?.passCreditCardFeesToCustomer || false,
			allowMultipleItems: edition?.allowMultipleItems || false
		},
		enableReinitialize: true,
		validationSchema: editionSchema,
		onSubmit: values => {
			setOpenAlert(false)
			mutate(
				{
					...values,
					editionId: edition?._id as string,
					experienceId: edition?.experience._id as string,
					vacancies: formik.values.vacancies
						? Number(formik.values.vacancies)
						: undefined,
					price: regex.parseBRLCurrencyToFloat(
						String(formik.values.price || 0)
					),
					limitDateToSell:
						formik.values.limitDateToSell?.toISOString() || undefined,
					startDate: formik.values.startDate?.toISOString() || undefined,
					pricingUnit: formik.values.pricingUnit || null,
					checkoutType,
					anticipationEnabled: values.anticipationEnabled,
					passAnticipationFeesToCustomer: values.passAnticipationFeesToCustomer,
					passCreditCardFeesToCustomer: values.passCreditCardFeesToCustomer
				},
				{
					onSuccess: ({responseStatusCode, error}) => {
						if (responseStatusCode === 200) {
							setShowSuccessButton(true)

							queryClient.invalidateQueries([
								`experiences-${sellerId}`,
								`experience-${edition?.experience.slug}`,
								`editions-${values.slug}`,
								`edition-${values.slug}`
							])

							window.history.replaceState(
								null,
								'Dashboard | Excofy',
								`/products/${edition?.experience.slug}/${values.slug}/edit`
							)
						}

						if (responseStatusCode === 400 && error?.fields) {
							Object.entries(error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						}

						if (responseStatusCode === 500) {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleOnChangeForm = () => {
		setShowSuccessButton(false)
		setOpenAlert(false)
	}

	const {isLoading: isCheckingSlug, mutate: mutateCheckSlug} = useMutation(
		checkEditionSlug,
		{
			networkMode: 'always'
		}
	)

	const checkAvailableSlug = (slug: string) => {
		if (
			!formik.errors.slug &&
			formik.values.slug !== '' &&
			lastSlugChecked !== formik.values.slug &&
			formik.values.slug !== edition?.slug
		) {
			setSlugAvailable(false)
			mutateCheckSlug(
				{
					slug: formik.values.slug,
					experienceId: edition?.experience._id as string
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setSlugAvailable(true)
							if (formik.values.slug !== result.data.slug) {
								setSlugHelper(t('helper-slug-already-in-use'))
							}
							formik.setFieldValue('slug', result.data.slug)
							setLastSlugChecked(result.data.slug)
						}
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.slug
						) {
							formik.setFieldError('slug', result.error.fields.slug[0].message)
						}
					}
				}
			)
		}
	}

	useEffect(() => {
		if (edition) {
			setCheckoutType(edition.checkoutType)
		}
	}, [edition])

	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
			onChange={handleOnChangeForm}
			sx={style.form.box}
		>
			<Dialog.Root open={openFeesCalculator} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesCalculator(false)}>
						{t('fees-calculator')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<FormFeesCalculator
						amount={regex.parseBRLCurrencyToFloat(
							String(formik.values.price || 0)
						)}
						installments={2}
						paymentMethod={paymentMethod}
						setPaymentMethod={setPaymentMethod}
						onChange={() => {}}
						onSuccess={data => {
							if (paymentMethod === 'credit-card') {
								setCreditCardFees(data as CreditCardFeesCalculatorData)
							} else if (paymentMethod === 'pix') {
								setPixFees(data as PixFeesCalculatorData)
							} else {
								setBoletoFees(data as BoletoFeesCalculatorData)
							}
							setOpenFeesDetails(true)
						}}
					/>
				</Dialog.Body>
			</Dialog.Root>
			<Dialog.Root open={openFeesDetails} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesDetails(false)}>
						{t('fees-details')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<Card variant="outlined">
						<Box>
							{creditCardFees && paymentMethod === 'credit-card' ? (
								<CreditCardFees fees={creditCardFees} />
							) : null}

							{boletoFees && paymentMethod === 'boleto' ? (
								<BoletoFees fees={boletoFees} />
							) : null}

							{pixFees && paymentMethod === 'pix' ? (
								<PixFees fees={pixFees} />
							) : null}
						</Box>
					</Card>
				</Dialog.Body>
			</Dialog.Root>
			<Dialog.Root open={openSimulateSale}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenSimulateSale(false)}>
						{t('to-simulate-sale')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<SaleSimulate />
				</Dialog.Body>
			</Dialog.Root>
			<SectionTitle sx={{mb: 3}}>{t('edit-edition')}</SectionTitle>
			<Stack spacing={4}>
				<Card
					variant="outlined"
					sx={{
						p: 3
					}}
				>
					{!edition ? (
						<Stack direction="column" spacing={4}>
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<FormHelperText sx={{my: '8px !important', px: 1}}>
								<Skeleton variant="rounded" height={12} />
							</FormHelperText>
							<Skeleton variant="rounded" height={125} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rectangular" height={40} />
						</Stack>
					) : (
						<>
							<Stack direction="column" spacing={4} pr={3}>
								<Typography variant="h5" sx={{mb: 2}}>
									{t('general-information')}
								</Typography>
								{openAlert && (
									<AlertMessage
										open={openAlert}
										severity="error"
										message={alertMessage}
										onClose={() => setOpenAlert(false)}
										mt={0}
										sx={{
											mr: 3
										}}
									/>
								)}
								<TextField
									name="title"
									type="text"
									label={t('title')}
									value={formik.values.title}
									onChange={event => {
										const currentSlug = regex.formatStringToSlug(
											event.target.value
										)
										formik.setFieldValue('slug', currentSlug)
										formik.handleChange(event)
									}}
									onBlur={event => {
										formik.handleBlur(event)
										checkAvailableSlug(event.target.value)
									}}
									helperText={formik.touched.title && formik.errors.title}
									error={formik.touched.title && Boolean(formik.errors.title)}
									variant="outlined"
									size="small"
									inputProps={{maxLength: INPUT.EDITION.title.max}}
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-title-offer')}
												</Typography>
											</InfoPopover>
										)
									}}
									sx={{mt: '32px !important'}}
								/>
								<TextField
									name="slug"
									type="text"
									label={t('slug')}
									value={formik.values.slug}
									onChange={event => {
										formik.handleChange(event)
										setSlugHelper(t('helper-slug'))
									}}
									onBlur={event => {
										formik.handleBlur(event)
										checkAvailableSlug(event.target.value)
									}}
									helperText={
										formik.touched.slug && Boolean(formik.errors.slug)
											? formik.errors.slug
											: slugHelper
									}
									error={formik.touched.slug && Boolean(formik.errors.slug)}
									variant="outlined"
									size="small"
									inputProps={{maxLength: INPUT.EDITION.slug.max}}
									InputProps={{
										endAdornment: (
											<>
												<InputAdornment position="end">
													{isCheckingSlug && (
														<CircularProgress color="primary" size={20} />
													)}
													{!isCheckingSlug &&
														slugAvailable &&
														(lastSlugChecked === formik.values.slug ||
															formik.values.slug === edition?.slug) && (
															<IconButton sx={style.form.inputCheckIcon}>
																<CheckIcon size={20} />
															</IconButton>
														)}
												</InputAdornment>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-slug')}
													</Typography>
												</InfoPopover>
											</>
										)
									}}
								/>
								<TextField
									name="description"
									type="text"
									label={t('description')}
									multiline
									rows={4}
									value={formik.values.description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={
										formik.touched.description && formik.errors.description
									}
									error={
										formik.touched.description &&
										Boolean(formik.errors.description)
									}
									inputProps={{maxLength: INPUT.EDITION.description.max}}
									variant="outlined"
									size="small"
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-edition-description')}
												</Typography>
											</InfoPopover>
										)
									}}
								/>
								<Box
									sx={theme => ({
										display: 'flex',
										gap: 6,
										[theme.breakpoints.down('sm')]: {
											flexDirection: 'column',
											gap: 4
										}
									})}
								>
									<TextField
										name="price"
										label={t('price')}
										value={formik.values.price}
										onChange={event => {
											event.target.value = regex.formatBRLCurrency(
												event.target.value
											)
											formik.handleChange(event)
										}}
										onBlur={formik.handleBlur}
										helperText={formik.touched.price && formik.errors.price}
										error={formik.touched.price && Boolean(formik.errors.price)}
										variant="outlined"
										size="small"
										InputProps={{
											startAdornment: (
												<>
													<InputAdornment position="start">
														{'R$'}
													</InputAdornment>
													<InfoPopover sx={{position: 'absolute', right: -34}}>
														<Typography variant="body2">
															{t('info-edition-price')}
														</Typography>
													</InfoPopover>
												</>
											),
											endAdornment: (
												<ButtonIcon
													title={t('to-simulate-sale')}
													size="medium"
													padding={0.75}
													icon={<CalculatorIcon />}
													onClick={() => setOpenSimulateSale(true)}
												/>
											)
										}}
										fullWidth
									/>
									<Autocomplete
										freeSolo
										id="free-solo-demo"
										options={[
											t('unit-item'),
											t('unit-person'),
											t('unit-service'),
											t('unit-spot')
										].map(option => option)}
										value={formik.values.pricingUnit}
										onChange={(event, value) => {
											formik.setFieldValue('pricingUnit', value)
											handleOnChangeForm()
										}}
										fullWidth
										renderInput={params => (
											<TextField
												{...params}
												name="pricingUnit"
												label={t('unit')}
												value={formik.values.pricingUnit}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												helperText={
													formik.touched.pricingUnit &&
													Boolean(formik.errors.pricingUnit) ? (
														formik.errors.pricingUnit
													) : (
														<Tag>{t('optional')}</Tag>
													)
												}
												error={
													formik.touched.pricingUnit &&
													Boolean(formik.errors.pricingUnit)
												}
												variant="outlined"
												size="small"
											/>
										)}
									/>
								</Box>
								<TextField
									name="vacancies"
									type="text"
									label={t('vacancies')}
									value={formik.values.vacancies?.toString() || ''}
									onChange={event => {
										event.target.value = regex.formatStringToNumber(
											event.target.value
										)
										formik.handleChange(event)
									}}
									onBlur={formik.handleBlur}
									helperText={
										formik.touched.vacancies &&
										Boolean(formik.errors.vacancies) ? (
											formik.errors.vacancies
										) : (
											<Tag>{t('optional')}</Tag>
										)
									}
									error={
										formik.touched.vacancies && Boolean(formik.errors.vacancies)
									}
									variant="outlined"
									size="small"
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-edition-vacancies')}
												</Typography>
											</InfoPopover>
										)
									}}
								/>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale={convertLocaleToDayjs(currentLanguage)}
								>
									<FormControl
										sx={[style.form.control, style.form.controlDate]}
									>
										<Box
											sx={theme => ({
												display: 'flex',
												position: 'relative',
												width: '50%',
												[theme.breakpoints.down('sm')]: {
													width: '100%'
												}
											})}
										>
											<DatePicker
												label={t('limit-date-to-sell')}
												value={formik.values.limitDateToSell}
												minDate={dayjs(Date.now())}
												dayOfWeekFormatter={day => `${day}`}
												onChange={newValue => {
													formik.setFieldValue('limitDateToSell', newValue)
												}}
												open={openDatePickerLimitDate}
												onClose={() => {
													setOpenDatePickerLimitDate(false)
												}}
												slotProps={{
													textField: {
														name: 'limitDateToSell',
														type: 'text',
														label: t('limit-date-to-sell'),
														value: formik.values.limitDateToSell,
														error: Boolean(formik.errors.limitDateToSell),
														helperText:
															formik.touched.limitDateToSell &&
															Boolean(formik.errors.limitDateToSell) ? (
																formik.errors.limitDateToSell
															) : (
																<Tag>{t('optional')}</Tag>
															),
														variant: 'outlined',
														size: 'small',
														sx: {
															flexGrow: 1
														},
														onClick: () => {
															setOpenDatePickerLimitDate(true)
														},
														InputProps: {
															endAdornment: null
														}
													}
												}}
												slots={<TextField />}
											/>
											{formik.values?.limitDateToSell && (
												<Tooltip title={t('to-remove')} arrow>
													<IconButton
														size="small"
														title={t('to-remove')}
														onClick={() =>
															formik.setFieldValue('limitDateToSell', null)
														}
														sx={{
															position: 'absolute',
															right: '8px',
															top: '6px'
														}}
													>
														<ClearIcon />
													</IconButton>
												</Tooltip>
											)}
											<Box sx={style.boxInfoPopover}>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-limit-date-to-sell')}
													</Typography>
												</InfoPopover>
											</Box>
										</Box>
										<Box
											sx={theme => ({
												display: 'flex',
												position: 'relative',
												width: '50%',
												[theme.breakpoints.down('sm')]: {
													width: '100%'
												}
											})}
										>
											<DatePicker
												value={formik.values.startDate}
												minDate={dayjs(Date.now())}
												dayOfWeekFormatter={day => `${day}`}
												onChange={newValue => {
													formik.setFieldValue('startDate', newValue)
												}}
												open={openDatePickerStartDate}
												onClose={() => {
													setOpenDatePickerStartDate(false)
												}}
												slots={<TextField />}
												slotProps={{
													textField: {
														name: 'startDate',
														type: 'text',
														label: t('start-date'),
														value: formik.values.startDate,
														helperText:
															formik.touched.startDate &&
															Boolean(formik.errors.startDate) ? (
																formik.errors.startDate
															) : (
																<Tag>{t('optional')}</Tag>
															),
														error: Boolean(formik.errors.startDate),
														variant: 'outlined',
														size: 'small',
														sx: {
															flexGrow: 1
														},
														onClick: () => {
															setOpenDatePickerStartDate(true)
														},
														InputProps: {
															endAdornment: null
														}
													}
												}}
											/>
											{formik.values?.startDate && (
												<Tooltip title={t('to-remove')} arrow>
													<IconButton
														size="small"
														title={t('to-remove')}
														onClick={() =>
															formik.setFieldValue('startDate', null)
														}
														sx={{
															position: 'absolute',
															right: '8px',
															top: '6px'
														}}
													>
														<ClearIcon />
													</IconButton>
												</Tooltip>
											)}
											<Box sx={style.boxInfoPopover}>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-start-date')}
													</Typography>
												</InfoPopover>
											</Box>
										</Box>
									</FormControl>
								</LocalizationProvider>
								<Accordion
									disableGutters
									square
									variant="outlined"
									sx={{
										borderRadius: '4px',
										'&.Mui-expanded': {
											mt: 4
										},
										'&.MuiAccordion-root::before': {
											display: 'none'
										}
									}}
								>
									<AccordionSummary
										expandIcon={<ArrowDropDownIcon />}
										aria-controls="advanced-options-content"
										id="advanced-options-header"
										sx={{
											backgroundColor: 'grey.100',
											borderTopLeftRadius: '4px',
											borderTopRightRadius: '4px',
											'&.MuiAccordionSummary-content': {
												my: '12px !important'
											}
										}}
									>
										<Typography variant="subtitle2" fontWeight={500}>
											{t('advanced-settings')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										sx={{
											borderTop: '1px solid',
											borderColor: 'divider',
											p: 2
										}}
									>
										<Stack spacing={4}>
											<FormControl sx={{gap: 0.5}}>
												<Typography variant="subtitle1" fontWeight={500}>
													{t('checkout-type')}
												</Typography>
												<FormControl>
													<RadioGroup
														aria-labelledby="checkout-type-label"
														name="checkoutType"
														onChange={event =>
															setCheckoutType(
																event.target.value as
																	| 'one-page'
																	| 'multiple-page'
															)
														}
													>
														<FormControlLabel
															value="one-page"
															checked={checkoutType === 'one-page'}
															control={
																<Radio
																	sx={{
																		fontSize: '0.875rem',
																		'&.MuiButtonBase-root': {
																			py: '6px'
																		}
																	}}
																/>
															}
															label={t('one-page-checkout')}
															sx={{color: 'text.secondary'}}
														/>
														<FormControlLabel
															value="multiple-page"
															checked={checkoutType === 'multiple-page'}
															control={
																<Radio
																	sx={{
																		'&.MuiButtonBase-root': {
																			py: '6px'
																		}
																	}}
																/>
															}
															label={t('multiple-page-checkout')}
															sx={{
																color: 'text.secondary'
															}}
														/>
													</RadioGroup>
												</FormControl>
											</FormControl>
											<FormControl sx={{gap: 1.25}}>
												<Typography variant="subtitle1" fontWeight={500}>
													{t('allow-multiple-items')}
												</Typography>
												<FormControlLabel
													control={
														<Checkbox
															sx={(theme: Theme) => ({
																color: theme.palette.grey[500],
																py: 0
															})}
															name="allowMultipleItems"
															checked={formik.values.allowMultipleItems}
															onChange={formik.handleChange}
														/>
													}
													label={
														<Typography sx={{color: 'text.secondary'}}>
															{t('allow-multiple-items-label')}
														</Typography>
													}
													sx={{alignItems: 'flex-start'}}
												/>
											</FormControl>
											<Box>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														flexWrap: 'wrap',
														columnGap: 2,
														rowGap: 1
													}}
												>
													<Typography variant="subtitle1" fontWeight={500}>
														{t('credit-card')}{' '}
													</Typography>
													<Button
														variant="outlined"
														size="small"
														startIcon={<CalculatorIcon />}
														onClick={() => setOpenSimulateSale(true)}
													>
														{t('to-simulate-sale')}
													</Button>
												</Box>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'flex-start',
														flexDirection: 'column',
														gap: 1.5,
														mt: 1.5
													}}
												>
													<Box sx={{display: 'flex', alignItems: 'center'}}>
														<FormControlLabel
															control={
																<Checkbox
																	sx={(theme: Theme) => ({
																		color: theme.palette.grey[500],
																		py: 0
																	})}
																	name="anticipationEnabled"
																	checked={formik.values.anticipationEnabled}
																	onChange={formik.handleChange}
																/>
															}
															label={
																<Typography sx={{color: 'text.secondary'}}>
																	{t('to-activate-anticipation')}
																</Typography>
															}
															sx={{
																'&.MuiFormControlLabel-root': {
																	mr: 0
																}
															}}
														/>
														<InfoPopover>
															{t('automatic-anticipation-describe-2')}
														</InfoPopover>
													</Box>
													<FormControlLabel
														control={
															<Checkbox
																sx={(theme: Theme) => ({
																	color: theme.palette.grey[500],
																	py: 0
																})}
																name="passAnticipationFeesToCustomer"
																checked={
																	formik.values.passAnticipationFeesToCustomer
																}
																onChange={formik.handleChange}
															/>
														}
														label={
															<Typography sx={{color: 'text.secondary'}}>
																{t(
																	'label-pass-credit-card-anticipation-fees-to-customer'
																)}
															</Typography>
														}
													/>
													<FormControlLabel
														control={
															<Checkbox
																sx={(theme: Theme) => ({
																	color: theme.palette.grey[500],
																	py: 0
																})}
																name="passCreditCardFeesToCustomer"
																checked={
																	formik.values.passCreditCardFeesToCustomer
																}
																onChange={formik.handleChange}
															/>
														}
														label={
															<Typography sx={{color: 'text.secondary'}}>
																{t('label-pass-credit-card-fees-to-customer')}
															</Typography>
														}
													/>
												</Box>
											</Box>
										</Stack>
									</AccordionDetails>
								</Accordion>
							</Stack>
							{showSuccessButton ? (
								<Button
									size="large"
									variant="contained"
									type="button"
									sx={style.form.successButton}
								>
									{t('saved-changes')}
								</Button>
							) : (
								<Button
									size="large"
									variant="contained"
									type="submit"
									disabled={isLoading}
									sx={style.form.submitButton}
								>
									{isLoading ? (
										<CircularProgress color="inherit" size={25} />
									) : (
										t('to-save-changes')
									)}
								</Button>
							)}
						</>
					)}
				</Card>
				{edition?.integrations && (
					<Box>
						<Typography variant="h5" mb={3}>
							{t('integrations')}
						</Typography>
						{edition?.integrations.orderBump && (
							<OrderBumpCard
								offerId={edition._id}
								productId={edition.experience._id}
								orderBump={edition?.orderBump}
								orderBumpIntegration={edition?.integrations.orderBump}
							/>
						)}
					</Box>
				)}
			</Stack>
		</Box>
	)
}

const style = {
	form: {
		box: (theme: Theme) => ({
			my: 5,
			mt: 0,
			mb: 5,
			pt: 0,
			width: '100%',
			maxWidth: '600px'
		}),
		control: (theme: Theme) => ({
			display: 'flex',
			flexDirection: 'row',
			width: '100%'
		}),
		controlDate: (theme: Theme) => ({
			gap: 6,
			[theme.breakpoints.down('sm')]: {
				gap: 4,
				flexDirection: 'column'
			}
		}),
		submitButton: (theme: Theme) => ({
			mt: 4,
			height: '44px',
			width: '200px',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		}),
		successButton: (theme: Theme) => ({
			mt: 4,
			height: '44px',
			backgroundColor: 'success.light',
			color: 'success.contrastText',
			width: '200px',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			},
			'&:hover': {backgroundColor: 'success.light'}
		}),
		inputCheckIcon: (theme: Theme) => ({
			color: theme.palette.success.main,
			p: 0
		}),
		inputDate: {
			flexGrow: 1
		}
	},
	boxInfoPopover: {
		position: 'relative',
		mt: 0.375
	}
}

export default FormUpdateEdition
