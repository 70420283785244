import IconButton from '@mui/material/IconButton'
import Link from './navigation/Link'
import Tooltip from '@mui/material/Tooltip'
import {Theme} from '@mui/material/styles'
import {useMemo} from 'react'
interface ButtonIconProps {
	icon: React.ReactNode
	title: string
	to?: string
	size?: 'small' | 'medium' | 'large'
	padding?: number | string
	state?: object
	target?: string
	disabled?: boolean
	externalLink?: boolean
	onClick?: (event: React.MouseEvent<HTMLElement>) => void
	onMouseLeave?: () => void
}

const ButtonIcon = ({
	icon,
	title,
	to,
	size: initialSize = 'large',
	padding,
	state,
	target,
	disabled,
	externalLink,
	onClick,
	onMouseLeave
}: ButtonIconProps) => {
	const size = useMemo(() => {
		switch (initialSize) {
			case 'small':
				return {width: 24, height: 24}
			case 'medium':
				return {width: 28, height: 28}
			default:
				return {width: 32, height: 32}
		}
	}, [initialSize])

	return (
		<Tooltip title={title} placement="bottom" arrow onClose={onMouseLeave}>
			{to ? (
				<IconButton
					onClick={onClick}
					sx={[styles.icon, size, {padding}]}
					disabled={disabled}
				>
					<Link
						to={to}
						state={state}
						sx={{color: 'inherit'}}
						target={target}
						externalLink={externalLink}
					>
						{icon}
					</Link>
				</IconButton>
			) : (
				<IconButton
					onClick={onClick}
					sx={[styles.icon, size, {padding}]}
					disabled={disabled}
				>
					{icon}
				</IconButton>
			)}
		</Tooltip>
	)
}

const styles = {
	icon: (theme: Theme) => ({
		backgroundColor: theme.palette.background.paper,
		borderRadius: '50%',
		border: '1px solid ' + theme.palette.divider,
		'&:hover': {
			borderColor: theme.palette.primary.dark
		},
		padding: 0.5
	})
}

export default ButtonIcon
